<template>
  <div class="row">
    <ConfirmModal
      ref="modal_delete_schedule"
      :modal-header-title="$t('common.delete confirm title')"
      :confirm-handle="deleteEntrySubmit"
      :on-hide="clearEntryToSave"
    ></ConfirmModal>
    <template v-for="(schedule_item, index_schedule) in schedule">
      <div style="border-style: none none double none; width: 100%" class="p-3 mb-4">
        <div class="d-flex justify-content-between w-100 mr-4">
          <div class="d-flex justify-content-between w-75">
            <AppInput
              type="string"
              :label="$t('tour_order_template.schedule_day') + ' ' + (index_schedule +1).toString()"
              input-style="normal"
              name="schedule[index_schedule]"
              @input="changeData(index_schedule)"
              v-model="schedule[index_schedule].title"
            />
            <AppDatePicker
              input-style="normal"
              name="schedule_date"
              @input="changeData(index_schedule)"
              :label="$t('tour_order_template.schedule_date')"
              v-model="schedule_item.date"
            />
            <AppInput
              input-style="normal"
              name="schedule_description"
              @input="changeData(index_schedule)"
              :label="$t('tour_order_template.schedule_des')"
              v-model="schedule[index_schedule].description"
              type="string"
            />
          </div>
          <button
            v-if="schedule.length > 1"
            @click="removeSchedule('schedule', index_schedule)"
            type="button"
            class="btn-tool delete mt-4"
            style="margin-right: 33px;"
          ></button>
        </div>
        <div>
          <table class="table dataTable app-table no-footer table_tab3" style="border: solid 1px #e4e4e4; border-bottom:  none !important;">
            <thead>
            <tr>
              <th style="min-width: 142px;">{{ $t('tour_order_template.schedule_time') }}</th>
              <th>{{ $t('tour_order_template.offices_name') }}</th>
              <th style="min-width: 120px;">{{ $t('tour_order_template.office_plans') }}</th>
              <th>{{ $t('tour_order_template.offices_explain') }}</th>
              <th>{{ $t('tour_order_template.offices_image') }}</th>
              <th>{{ $t('tour_order_template.offices_map_url') }}</th>
              <th>{{ $t('tour_order_template.offices_tel') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(schedule_item_item, index_schedule_item) in schedule_item.detail">
              <tr>
                <td class="text-center">
                  <VueTimepicker
                    input-style="normal"
                    name="time_schedule"
                    hour-label="時"
                    minute-label="分"
                    @input="changeData(index_schedule)"
                    v-model="schedule[index_schedule].detail[index_schedule_item].time_schedule"
                  />
                </td>
                <td>
                  <AppSelect
                    input-style="normal"
                    :options="{containerCssClass: 'z-index-0'}"
                    name="office_id"
                    :options-data="offices"
                    boundaryClass="m-auto"
                    v-model="schedule[index_schedule].detail[index_schedule_item].office_id"
                    @change="changeOffice($event, index_schedule, index_schedule_item)"
                  />
                </td>

                <td>
                  <AppSelect
                    input-style="normal"
                    :options="{containerCssClass: 'z-index-0'}"
                    :options-data="office_plans[index_schedule][index_schedule_item]"
                    boundaryClass="m-auto"
                    v-model="schedule[index_schedule].detail[index_schedule_item].plan_id"
                    @change="changeOfficePlan($event, index_schedule, index_schedule_item)"
                    v-if="office_plans[index_schedule] != undefined"
                  />
                </td>

                <td>
                  <AppText
                    type="text"
                    input-style="normal"
                    name="description"
                    v-model="schedule[index_schedule].detail[index_schedule_item].description"
                    @input="changeData(index_schedule)"
                    textarea-style="height: 50px"
                    boundaryClass="m-b-0 p-0"
                  />

                </td>

                <td>
                  <div v-if="schedule[index_schedule].detail[index_schedule_item].image != '' && schedule[index_schedule].detail[index_schedule_item].image != null"
                       class="w-100">
                    <img
                            height="80px"
                            width="80px"
                            :src="schedule[index_schedule].detail[index_schedule_item].image"
                    />
                    <div style="min-height:40px; display: inline-block;">
                      <div class="btn-tool delete table-remove-image" style="top:0;display:inline-block;vertical-align:middle;"
                           @click="removeScheduleImage(index_schedule, index_schedule_item, 'image_' + type_schedule + index_schedule + '_' + index_schedule_item)">
                      </div>
                    </div>
                  </div>
                  <input
                    :ref="'image_' + type_schedule + index_schedule + '_' + index_schedule_item"
                    :id="'image_' + type_schedule + index_schedule + '_' + index_schedule_item"
                    accept="image/jpg,image/jpeg,image/png"
                    style="height:40px;"
                    class="form-control"
                    type="file"
                    @change="uploadImage($event, index_schedule, index_schedule_item)"
                  >
                </td>

                <td>
                  <AppInput
                    boundary-class="m-b-0 p-0"
                    input-style="normal"
                    type="url"
                    rules="url"
                    name="map_url"
                    @input="changeData(index_schedule)"
                    v-model="schedule[index_schedule].detail[index_schedule_item].map_url"
                  />
                </td>

                <td>
                  <AppInput
                    boundary-class="m-b-0 p-0"
                    input-style="normal"
                    type="number-length:11"
                    rules="phone"
                    name="tel"
                    @input="changeData(index_schedule)"
                    v-model="schedule[index_schedule].detail[index_schedule_item].phone"
                  />
                </td>

                <td>
                  <button
                    v-if="schedule_item.detail.length > 1"
                    type="button"
                    class="btn-tool delete"
                    @click="removeSchedule('schedule_item', index_schedule, index_schedule_item)"
                  ></button>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
        <div class="col-md-12" style="margin-bottom:20px;margin-top:5px;padding-left: 0">
          <button
            @click="addSchedule('schedule_item', index_schedule)"
            type="button"
            class="btn btn-add-row"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </template>


    <div class="col-md-12 mt-3 d-flex justify-content-center w-75 pl-2" style="text-align:center;margin-bottom:20px;">
      <button
        @click="addSchedule('schedule', schedule.length)"
        type="button"
        class="btn btn-create"
      ><i class="fa fa-plus" aria-hidden="true"></i> スケジュールを追加</button>
    </div>
  </div>
</template>
<script>
  import App from "@/App";
  export default {
    props: ["schedule", "offices", "order_templates", "type_schedule", "userSchedules", "screen_type", "tour_order_template_id", 'checkFirst'],
    data() {
      return {
        office_plans: [],
        arrayOfficeId: [],
        officePlans: [],
        arrayOffice: [],
        delete: {
          name: "",
          key_1: "",
          key_2: "",
        },
      }
    },
    watch: {
      // 'schedule': {
      //   handler(val, oldVal) {
      //     this.getAllOfficePlansData(val)
      //   }
      // },
      'tour_order_template_id': {
        handler(val, oldVal) {
          this.arrayOfficeId = [];
        }
      },
      'schedule': {
        handler(schedules, oldSchedules) {
          // this.getAllOfficePlansData(val)
          if (schedules != undefined) {
            schedules.forEach(function(schedule, i, schedules) {
              let scheduleDetail = schedule.detail
              scheduleDetail.forEach(function(detail, j, scheduleDetail) {
                if (detail.office_id != oldSchedules[i].detail[j].office_id || detail.plan_id != oldSchedules[i].detail[j].plan_id) {
                  this.getAllOfficePlansData(schedules)
                }
              });
            });
          }
        }
      },
    },
    async mounted() {
      let schedules = []
      if (this.$route.name == 'ADMIN.BOOKING_UPDATE') {
        const res = await this.$request.get(this.$consts.API.ADMIN.BOOKING_SHOW(this.$route.params.id))
        schedules = res.data.data.tour_order.schedules
      } else if (this.$route.name == 'ADMIN.TOUR_ORDERS_UPDATE') {
        const res = await this.$request.get(this.$consts.API.ADMIN.TOUR_ORDER_TEMPLATES_EDIT(this.$route.params.id));
        schedules = res.data.data.schedules
      }
      await this.getAllOfficePlansData(schedules)
      await this.getOfficePlan()
    },
    methods: {
      deleteEntrySubmit()
      {
        if (this.delete.name == 'schedule_item') {
          this.schedule[this.delete.key_1].detail.splice(this.delete.key_2, 1);
        }

        if (this.delete.name == 'schedule') {
          this.schedule.splice(this.delete.key_1, 1);
        }

        this.delete = {
          name: "",
          key_1: "",
          key_2: "",
        }

        this.$refs.modal_delete_schedule.hide();
      },
      clearEntryToSave(){
        this.delete = {
          name: "",
          key_1: "",
          key_2: "",
        }
      },
      // id: officeId
      async changeOffice(id, indexSchedule, indexOffice)
      {
        if (this.checkFirst == false && this.screen_type === "booking_create_edit") {
          return
        }

        if (id != null && id != 0) {
          await this.getOfficePlansData(id, indexSchedule, indexOffice)
          const res = await this.$request.get(this.$consts.API.ADMIN.OFFICES_EDIT(id));
          if (res.data.data != undefined) {
            this.schedule[indexSchedule].detail[indexOffice].description = res.data.data.description;
            this.schedule[indexSchedule].detail[indexOffice].image = res.data.data.image;
            this.schedule[indexSchedule].detail[indexOffice].map_url = res.data.data.map_url;
            this.schedule[indexSchedule].detail[indexOffice].phone = res.data.data.tel;
            this.schedule[indexSchedule].detail[indexOffice].office_name = res.data.data.office_name;
          }
        }
      },
      async changeOfficePlan(id, indexSchedule, indexOffice)
      {
        let description = this.schedule[indexSchedule].detail[indexOffice].description
        let image = this.schedule[indexSchedule].detail[indexOffice].image
        let planId = this.schedule[indexSchedule].detail[indexOffice].plan_id
        if (id != null && id != undefined && id != 0 && id != "") {
          await this.getOfficePlan()
          let officePlan = this.officePlans.filter(((v, i, a) => v.id == id))
          if (officePlan.length != 0) {
            console.log('vao 1')
            this.schedule[indexSchedule].detail[indexOffice].plan_id = officePlan[0].id ? officePlan[0].id : planId;
            this.schedule[indexSchedule].detail[indexOffice].plan_name = officePlan[0].name;
            this.schedule[indexSchedule].detail[indexOffice].description = officePlan[0].description;
            this.schedule[indexSchedule].detail[indexOffice].image = officePlan[0].image_url;
          }
        } else {
          let officeId = this.schedule[indexSchedule].detail[indexOffice].office_id
          if (officeId && planId == 0) {
            console.log('vao 2')
            await this.getOfficePlan()
            let office = this.arrayOffice
            let dataOffice = office.find(element => element.id == officeId)
            this.schedule[indexSchedule].detail[indexOffice].plan_id = 0;
            this.schedule[indexSchedule].detail[indexOffice].plan_name = "";
            this.schedule[indexSchedule].detail[indexOffice].description = dataOffice.description ? dataOffice.description : description;
            this.schedule[indexSchedule].detail[indexOffice].image = dataOffice.image ? dataOffice.image : image;
          }
        }
      },
      addSchedule(type, index)
      {
        let cloneScheduleDetail = {
          time_schedule: "",
          office_id: 0,
          plan_id: 0,
          office_name: "",
          plan_name: "",
          description: "",
          image: "",
          map_url: "",
          phone: "",
        };
        if (type == "schedule") {
          let day = Number(this.schedule.length) + 1;
          if(this.office_plans[index] == undefined) {
            this.office_plans[index] = []
          }
          this.schedule.push({
            title: "",
            date: "",
            description: "",
            detail: [
              cloneScheduleDetail
            ]
          });
        }

        if (type == "schedule_item") {
          this.schedule[index].detail.push(cloneScheduleDetail);
        }
      },
      removeSchedule(type, index1, index2)
      {
        this.$refs.modal_delete_schedule.show();
        this.delete = {
          name: type,
          key_1: index1,
          key_2: index2,
        }
      },
      async uploadImage(event, index1, index2)
      {
        const image = event.target.files[0];
        if (this.checkImage(image)) {
          event.target.value = null;
          this.$appNotice.error(this.$t('dialog.image_type'));

          return;
        }

        if ((image.size / 1048576) > 5) {
          event.target.value = null;
          this.$appNotice.error(this.$t('dialog.image_max', {size: '5MB'}));

          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = e =>{
          this.uploadImage1(e.target.result, index1, index2)
        };
      },
      async uploadImage1(file, index1, index2)
      {
        let path="tours/schedules";

        const res = await this.$request.post(this.$consts.API.ADMIN.UPLOAD_IMAGE, {
          image: file,
          path: path,
        });

        this.schedule[index1].detail[index2].image = res.data.data;
        this.changeData(index1)
      },
      removeScheduleImage(index_schedule, index_schedule_item, input)
      {
        this.schedule[index_schedule].detail[index_schedule_item].image = '';
        this.$refs[input][0].value = null
      },

      changeData(index_schedule) {

      },
      async getOfficePlansData(officeId, indexSchedule, indexOffice) {
        let dataOffice = {};
        const emptyPlan = {id: '', name: ''};
        if (officeId != null && officeId != 0) {
          let cloneOffices = JSON.parse(localStorage.getItem('OFFICE_PLANS'));
          if (cloneOffices == undefined || cloneOffices == null) {
            cloneOffices = [];
          }
          if (cloneOffices == undefined || cloneOffices[officeId] == undefined) {
            const res = await this.$request.get(this.$consts.API.ADMIN.OFFICE_PLANS_LIST_NAME(officeId));
            if (!res.hasErrors()) {
              dataOffice = res.data;
            }
            cloneOffices[officeId] = res.data;
            localStorage.setItem('OFFICE_PLANS', JSON.stringify(_.cloneDeep(cloneOffices)));
          } else {
            dataOffice = cloneOffices[officeId];
          }

          if (this.office_plans[indexSchedule] == undefined) {
            this.office_plans[indexSchedule] = [];
          }
          this.office_plans[indexSchedule][indexOffice] = dataOffice ? dataOffice : [];
          this.$forceUpdate();
          this.office_plans[indexSchedule][indexOffice].unshift(emptyPlan)
        }
      },

      async getAllOfficePlansData(schedules) {
        const emptyPlan = {id: '', name: ''};
        let indexSchedule = 0;
        let arrayOfficeSchedule = []
        for (const schedule of schedules) {
          for (const detail of schedule.detail) {
            let officeId = _.cloneDeep(detail.office_id)
            if (officeId != null) {
              arrayOfficeSchedule.push(officeId)
            }
          }
        }

        if (schedules.length == 1 || schedules.length == 0) {
          if (this.office_plans[0] == undefined) {
            this.office_plans[0] = [];
          }
          this.office_plans[0][0] = []
        }

        arrayOfficeSchedule = arrayOfficeSchedule.filter((v, i, a) => a.indexOf(v) === i)
        const officePlanData = await this.$request.get(this.$consts.API.ADMIN.UI_HELPER_OFFICE_SCHEDULE_LIST, {arrayOfficeSchedule});
        if (!officePlanData.hasErrors()) {
          for (const schedule of schedules) {
            let indexOffice = 0;
            for (const detail of schedule.detail) {
              let officeId = _.cloneDeep(detail.office_id)
              if (officeId != null && officeId != 0 && !this.arrayOfficeId.includes(officeId + indexSchedule + indexOffice)) {
                this.arrayOfficeId.push(officeId + indexSchedule + indexOffice)
                let officePlan = officePlanData.data.officeSchedule.filter(((v, i, a) => v.id == officeId))
                if (this.office_plans[indexSchedule] == undefined) {
                  this.office_plans[indexSchedule] = [];
                }
                this.office_plans[indexSchedule][indexOffice] = officePlan[0]['officePlans'] ? officePlan[0]['officePlans'] : [];
                let plan = this.office_plans[indexSchedule][indexOffice]
                plan.splice(plan.findIndex(function(i){
                  return i.id == '' && i.name == '';
                }), 1)
                this.office_plans[indexSchedule][indexOffice].unshift(emptyPlan)
              } else {
                if (officeId == null || officeId == 0 || officeId == undefined) {
                  if (this.office_plans[indexSchedule] == undefined) {
                    this.office_plans[indexSchedule] = [];
                  }
                  this.office_plans[indexSchedule][indexOffice] = []
                }
              }
              indexOffice++;
            }
            indexSchedule++;
          }
        }
      },

      async getOfficePlan() {
        const res = await this.$request.get(this.$consts.API.ADMIN.UI_HELPER_PLANS_LIST)
          if (!res.hasErrors()) {
            this.officePlans = res.data.namePlans;
            this.arrayOffice = res.data.offices;
          }
      }
    }
  }
</script>

<style scoped>
table.table.dataTable.app-table.no-footer td {
  vertical-align: middle;
}
table.table.dataTable.app-table.no-footer td .form-group {
  margin-bottom: 0 !important;
}
table.table.dataTable.app-table.no-footer input,
table.table.dataTable.app-table.no-footer select,
.select2-container .select2-selection--single {
  min-height: 40px !important;
  height: 40px !important;
}

table.table.dataTable.app-table.no-footer td:nth-of-type(2) {
  width: 15%;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 35px !important;
}
.select2-container .select2-selection .select2-selection__arrow {
  bottom: 0 !important;
  height: 40px;
}
.vue__time-picker .controls > * {
  height: 40px !important;
  min-height: 40px;
}

.table_tab3 tbody td:last-child {
  width: 45px;
}
</style>
