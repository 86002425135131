<template>
  <div class="row col-md-12">
      <div class="col-md-6 col-xs-12 p-3 b-r-area b-b-area b-t-area">
        <AppSelect name="discuss_method_select" input-style="normal"
                   :options="{containerCssClass: 'z-index-0', placeholder: $t('booking.discuss_method')}"
                   :options-data="meta.discuss_method_select"
                   :label="$t('booking.discuss_method_label')"
                   v-model="entry.discuss_method"/>
        <div class="row">
          <div class="col-md-6 p-l-0">
            <AppDatePicker name="discuss_date_1" input-style="normal" :label="$t('booking.discuss_time')"
                           v-model="entry.discuss_time[0].date"/>
            <AppDatePicker name="discuss_date_2" input-style="normal"
                           v-model="entry.discuss_time[1].date"/>
            <AppDatePicker name="discuss_date_3" input-style="normal"
                           v-model="entry.discuss_time[2].date"/>
          </div>
          <div class="col-md-6 m-t-20 p-r-0">
            <AppSelect name="discuss_time_1" input-style="normal"
                       :options="{containerCssClass: 'z-index-0'}"
                       :options-data="meta.discuss_time_select"
                       v-model="entry.discuss_time[0].time"/>
            <AppSelect name="discuss_time_2" input-style="normal"
                       :options="{containerCssClass: 'z-index-0'}"
                       :options-data="meta.discuss_time_select"
                       v-model="entry.discuss_time[1].time"/>
            <AppSelect name="discuss_time_3" input-style="normal"
                       :options="{containerCssClass: 'z-index-0'}"
                       :options-data="meta.discuss_time_select"
                       v-model="entry.discuss_time[2].time"/>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <AppText name="note" input-style="normal" type="text"
                 boundaryClass="col-md-11"
                 :label="$t('booking.tour_note')"
                 v-model="entry.note"/>
      </div>
  </div>
</template>
<script>
  export default {
    name: "FirstSupport",
    props: ["entry", 'meta'],
  }
</script>
