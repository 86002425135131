const mapObjToSelectArray = (obj) => {
  var arr = [];
  Object.keys(obj).forEach(key => {
    arr.push({id: key, name: obj[key]});
  })
  return arr;
}

const DISCUSS_METHOD_MAP = {
  0: '選択してください。',
  1: 'Zoom',
  2: 'メール',
  3: '電話',
};
const DISCUSS_TIME_MAP = {
  1: '10時～12時',
  2: '12時～14時',
  3: '15時～17時',
};
const PAYMENT_STATUS_NOT_PAYMENT = 1;
const PAYMENT_STATUS_PAYMENT_DONE = 2;
const PAYMENT_STATUS_PAYMENT_FAIL = 3;
const PAYMENT_STATUS_PAYMENT_TRANSFER = 4;
const PAYMENT_STATUS_PAYMENT_TRANSFER_WAITING = 5;
const PAYMENT_STATUS_PAYMENT_ERROR = 6;

const PAYMENT_STATUS_MAP = {
  [PAYMENT_STATUS_NOT_PAYMENT]: '未決済',
  [PAYMENT_STATUS_PAYMENT_DONE]: '決済済',
  // [PAYMENT_STATUS_PAYMENT_FAIL]: '決済失敗',
  [PAYMENT_STATUS_PAYMENT_TRANSFER]: '振込済',
  [PAYMENT_STATUS_PAYMENT_TRANSFER_WAITING]: '振込待ち',
  [PAYMENT_STATUS_PAYMENT_ERROR]: '入金エラー',
}
const PROPOSAL_STATUS_MAP = {
  1: '修正予定',
  2: '提出済',
  3: '提出済・検討中',
  4: '承認済',
  5: 'なし',
}
const INVOICE_STATUS_MAP = {
  1: '寄付金差額なし',
  2: '作成中',
  3: '検討後作成',
  4: '修正予定',
  5: 'なし'
}
const STATUS_MAP = {
  1: '新規打ち合わせ希望',
  2: '相談中',
  3: '申込済',
  4: 'ツアー実施完了',
  5: 'キャンセル',
  6: '注文キャンセル'
}
export default {
  DISCUSS_METHOD_MAP,
  DISCUSS_METHOD_SELECT: mapObjToSelectArray(DISCUSS_METHOD_MAP),
  DISCUSS_TIME_MAP,
  DISCUSS_TIME_SELECT: mapObjToSelectArray(DISCUSS_TIME_MAP),

  PAYMENT_STATUS_NOT_PAYMENT,
  PAYMENT_STATUS_PAYMENT_DONE,
  PAYMENT_STATUS_PAYMENT_FAIL,
  PAYMENT_STATUS_PAYMENT_TRANSFER,
  PAYMENT_STATUS_PAYMENT_TRANSFER_WAITING,
  PAYMENT_STATUS_PAYMENT_ERROR,
  PAYMENT_STATUS_MAP,
  PAYMENT_STATUS_SELECT: mapObjToSelectArray(PAYMENT_STATUS_MAP),

  PROPOSAL_STATUS_MAP,
  PROPOSAL_STATUS_SELECT: mapObjToSelectArray(PROPOSAL_STATUS_MAP),
  INVOICE_STATUS_MAP,
  INVOICE_STATUS_SELECT: mapObjToSelectArray(INVOICE_STATUS_MAP),
  STATUS_MAP,
  STATUS_SELECT: mapObjToSelectArray(STATUS_MAP)
}
