<template>
	<div class="container-fluid container-fixed-lg bg-white app-table-container">
		<ConfirmModal
				ref="modal_delete_entry"
				:modal-header-title="$t('common.delete confirm title')"
				:confirm-handle="deleteEntrySubmit"
				:on-hide="clearEntryToSave"
		>
		</ConfirmModal>
		<ConfirmModal
				ref="modal_confirm_update"
				:modal-header-title="$t('booking.modal_confirm_update_title')"
				:confirm-handle="storeOrUpdateBookingRequest"
		>
			<template v-slot:body>
				<p class="confirm-body">{{ $t('booking.modal_confirm_update_body') }}</p>
			</template>
		</ConfirmModal>
		<ConfirmModal
				ref="modal_confirm_cancel"
				:modal-header-title="$t('common.confirm_cancel_title')"
				:confirm-handle="redirectBack">
		</ConfirmModal>
		<div class="card card-transparent">
			<div class="card-body">
				<div class="row">
					<div data-pages="card" class="card card-default">
						<div class="card-body">
							<ValidationObserver ref="form_create_or_update" v-slot="{ passes, valid, validated }">
								<form novalidate autocomplete="off" @submit.prevent="() => {}">
									<div class="justify-content-end row m-t-20 m-b-20">
										<button @click="cancelCreateOrUpdate" type="button"
												:class="['btn btn-default btn-cons pull-right btn-modal-cancel']">
											{{ $t('common.cancel') }}
										</button>
										<button @click="storeOrUpdateBooking" type="submit"
												:class="['btn inline pull-right', 'btn-complete']">
											{{ idEditing ? $t('common.update_btn') : $t('common.save') }}
										</button>
									</div>

									<div :class="['col-md-12 tour-order-title row' , {'tour-order-completed' : (entry.first_time_support_review_status == 2)}]"
											@click="content_1 = !content_1">
										<label class="control-label input-label p-0" style="margin-left:10px;">
											{{ $t('booking.1_title_first_support') }}
										</label>
										<span class="float-right"><i :class="['fa' , content_1 ? 'fa-caret-down' : 'fa-caret-up']"></i></span>
									</div>
									<div v-if="content_1" :class="['row m-t-20', {'display-none' : !content_1}]">
										<FirstSupport :entry="entry" :content_1="content_1" :meta="meta"></FirstSupport>
										<div class="col-md-12 text-center">
											<button type="button" style="color: #000000;"
													@click="entry.first_time_support_review_status = 2"
													:class="['btn btn-secondary btn-cons btn-modal-cancel m-r-5']">
												{{ $t('common.completed') }}
											</button>
											<button type="button"
													@click="entry.first_time_support_review_status = 1"
													:class="['btn btn-warning']" style="color: #000000;">
												{{ $t('common.draft') }}
											</button>
										</div>
									</div>

									<div :class="['col-md-12 tour-order-title row m-t-20' , {'tour-order-completed' : (entry.input_hearing_sheet_review_status == 2)}]"
											@click="content_2 = !content_2">
										<label class="control-label input-label p-0" style="margin-left:10px;">
											{{ $t('booking.2_input_heading_sheet') }}
										</label>
										<span class="float-right"><i
												:class="['fa' , content_2 ? 'fa-caret-down' : 'fa-caret-up']"></i></span>
									</div>
									<div v-if="content_2" :class="['row m-t-20', {'display-none' : !content_2}]">
										<HeadingSheet :entry="entry" :content_1="content_1" :meta="meta"></HeadingSheet>
										<div class="col-md-12 text-center">
											<button type="button" style="color: #000000;"
													@click="entry.input_hearing_sheet_review_status = 2"
													:class="['btn btn-secondary btn-cons btn-modal-cancel m-r-5']">
												{{ $t('common.completed') }}
											</button>
											<button type="button"
													@click="entry.input_hearing_sheet_review_status = 1"
													:class="['btn btn-warning']" style="color: #000000;">
												{{ $t('common.draft') }}
											</button>
										</div>
									</div>

									<div :class="['col-md-12 tour-order-title row m-t-20' , {'tour-order-completed' : (entry.input_tour_plan_review_status == 2)}]"
											@click="content_3 = !content_3">
										<label class="control-label input-label p-0" style="margin-left:10px;">
											{{ $t('booking.3_travel_planing') }}
										</label>
										<span class="float-right"><i :class="['fa' , content_3 ? 'fa-caret-down' : 'fa-caret-up']"></i></span>
									</div>
									<div :class="['row m-t-20', {'display-none' : !content_3}]">
										<div class="row">
											<div class="col-md-12">
												<div class="col-md-6" style="margin-bottom:10px">
													<AppSelect
															input-style="normal"
															name="entry.tour_order.type"
															rules="required"
															:label="$t('tour_order_template.tour_type')"
															boundaryClass="m-auto"
															:options="{containerCssClass: 'z-index-0', placeholder: $t('booking.customer_name_select')}"
															:options-data="tour_types"
															:value="entry.tour_order.type"
															v-model="entry.tour_order.type"
															:value_empty="false"
													/>
												</div>
											</div>
											<div class="col-md-12">
												<div class="col-md-6">
													<AppInput v-if="entry.status == 4 && entry.tour_order_template == null && entry.tour_order != null" name="tour_order_name"
																		input-style="normal"
																		type="string"
																		:label="$t('booking.tour_order_name')"
																		:disabled="true"
																		v-model="entry.tour_order.name"/>
													<AppSelect v-else name="tour_order_name" input-style="normal"
																		 :options="{containerCssClass: 'z-index-0', placeholder: $t('booking.tour_order_name')}"
																		 :options-data="meta.tour_orders_template_select"
																		 :label="$t('booking.tour_order_name')"
																		 @change="getTourDetail"
																		 v-model="entry.tour_order_template_id"/>
												</div>
											</div>
											<div class="col-md-12">
												<div v-if="entry.tour_order.type == 2" class="col-md-9 m-b-10">
													<label class="control-label input-label p-0">
														{{ $t('tour_order_template.furusato_type') }}
													</label>
													<div class="col-md-8 m-t-10 p-3"
														 style="border: 1px solid grey; margin-top: 10px !important;">
														<div class="col-md-12 m-b-10">
															<AppSelect
																	:label="$t('tour_order_template.furusato_tour_city')"
																	input-style="normal"
																	name="furusato_tour_city_id"
																	boundaryClass="m-auto"
																	:options="{containerCssClass: 'z-index-0', placeholder: $t('booking.customer_name_select')}"
																	:options-data="options_cities"
																	v-model="entry.tour_order.furusato_tour_city_id"
																	:value_empty="false"
															/>
														</div>
														<div class="col-md-12 m-b-10">
															<AppInput
																	type="string"
																	name="furusato_code"
																	:label="$t('tour_order_template.furusato_code')"
																	input-style="normal"
																	v-model="entry.tour_order.furusato_code"
															/>
														</div>
<!--														<div class="col-md-12 m-b-10">-->
<!--															<AppText-->
<!--																	type="text"-->
<!--																	name="furusato_from_site"-->
<!--																	:label="$t('tour_order_template.furusato_from_site')"-->
<!--																	input-style="normal"-->
<!--																	v-model="entry.tour_order.furusato_from_site"-->
<!--															/>-->
<!--														</div>-->
													</div>
												</div>
											</div>
											<div class="col-md-12">
												<div class="col-md-12">
													<label class="control-label input-label p-0">
														{{ $t('tour_order_template.area_label') }}
													</label>
												</div>
												<div class=" w-100" style="padding: 8px;">
													<div class="col-md-6 w-100 pb-2"
														 style="border: 1px solid grey; margin-top: 10px !important;">
														<div class="mr-2 ml-2">
															<table class="table dataTable app-table no-footer">
																<thead>
																<tr>
																	<th>{{ $t('tour_order_template.area_prefecture') }}</th>
																	<th>{{ $t('tour_order_template.area_city') }}</th>
																	<th></th>
																</tr>
																</thead>
																<tbody>
																<template v-for="(area, index_area) in areas">
																	<tr>
																		<td style="width: 45%">
																			<AppSelect
																					:onChange="(id) => changePrefecture(id, index_area)"
																					input-style="normal"
																					name="prefecture_id"
																					boundaryClass="m-auto"
																					:options="{containerCssClass: 'z-index-0'}"
																					:options-data="options_prefecture"
																					:value="areas[index_area].prefecture_id"
																					v-model="areas[index_area].prefecture_id"
																					:value_empty="false"
																			/>
																		</td>
																		<td style="width: 45%">
																			<AppSelect
																					:options="{containerCssClass: 'z-index-0'}"
																					:onChange="(id) => changeCity(id, index_area)"
																					input-style="normal"
																					name="city_id"
																					boundaryClass="m-auto"
																					:options-data="area.cities"
																					:value="areas[index_area].city_id"
																					v-model="areas[index_area].city_id"
																					:value_empty="false"
																			/>
																		</td>
																		<td style="width: 10%">
																			<div v-if="areas.length > 1"
																				 class="btn-tool delete"
																				 @click="removeArea(index_area)"
																				 style="margin: auto; margin-top: 5px"></div>
																		</td>
																	</tr>
																</template>
																</tbody>
															</table>
														</div>
														<div class="m-t-5 ml-2 row w-100">
															<button
																	@click="addArea()"
																	type="button"
																	class="btn btn-add-row"
															>
																<i class="fa fa-plus" aria-hidden="true"></i>
															</button>
														</div>
													</div>
												</div>
											</div>
											<div class="col-md-12">
												<div class="col-md-6 mt-4">
													<AppInput
															type="url"
															name="public_url"
															:label="$t('tour_order_template.public_url')"
															input-style="normal"
															:disabled="true"
															v-model="entry.tour_order.public_url"
													/>
												</div>
											</div>
											<div class="col-md-12 m-t-10">
												<div class="col-md-12">
													<label class="control-label input-label p-0">
														{{ $t('booking.tour_overview') }}
													</label>
												</div>
												<div class=" w-100" style="padding: 8px;">
													<div class="col-md-12 row"
														 style="border: 1px solid grey; margin-top: 10px !important;">
														<div class="col-md-6 p-t-20">
															<AppInput v-if="entry.status == 4 && entry.member.active == 0"
																	  name="member_id"
																	  input-style="normal"
																	  type="string"
																	  :label="$t('booking.member_name')"
																	  :disabled="true"
																	  v-model="entry.member.sei+ '　' +entry.member.mei"/>
															<AppSelect v-else name="entry[member_id]" input-style="normal"
																	   :options="{containerCssClass: 'z-index-0', placeholder: $t('booking.customer_name_select')}"
																	   :options-data="meta.member_select"
																	   rules="required"
																	   :label="$t('booking.customer_name')"
																	   v-model="entry.member_id"/>
														</div>
														<div class="col-md-6 p-t-20">
															<AppSelect name="type" input-style="normal"
																	   :options="{containerCssClass: 'z-index-0', placeholder: $t('booking.user_type')}"
																	   :options-data="user_tour_types"
																	   rules="required"
																	   :label="$t('booking.user_type')"
																	   v-model="entry.type"/>
														</div>
														<div class="col-md-6">
															<AppInput v-if="entry.status == 4 && entry.admin != undefined && entry.admin.active == 0"
																	  name="admin_id"
																	  input-style="normal"
																	  type="string"
																	  :label="$t('booking.admin_name_select')"
																	  :disabled="true"
																	  v-model="entry.admin.name"/>
															<AppSelect v-else name="admin_id" input-style="normal"
																	   :options-data="meta.admin_select"
																	   :options="{containerCssClass: 'z-index-0', placeholder: $t('booking.admin_name_select')}"
																	   :label="$t('booking.admin_name_select')"
																	   v-model="entry.admin_id"/>
														</div>
														<div class="col-md-6">
															<AppInput name="amount"
																	  input-style="normal"
																	  type="string"
																	  :disabled="true"
																	  :value="formatPrice(entry.tour_order.price)"
																	  :label="$t('booking.tour_order_price')"
																	  v-model="show_price"
															>
																<template slot="append">
																	<span style="margin: auto" class="app-text-bold">{{ $t('common.yen_icon') }}</span>
																</template>
															</AppInput>
														</div>
														<div class="col-md-6 row">
															<div class="col-md-12 p-0">
																<label class="control-label input-label p-0"
																	   style="margin-top:15px;">{{
																	$t('booking.people_num') }}</label>
															</div>
															<div class="col-md-1 p-0">
																<label class="control-label input-label p-0"
																	   style="margin-top:15px;">{{
																	$t('booking.adult') }}</label>
															</div>
															<div class="col-md-5">
																<AppInput name="adult_count_tour" input-style="normal" type="bigint"
																		  rules="min_value:1|max_value:99"
																		  v-model="entry.adult_count_tour">
																	<template slot="append">
																	<span style="margin: auto" class="app-text-bold">{{
																		  $t('common.people_count_icon')}}</span>
																	</template>
																</AppInput>
															</div>
															<div class="col-md-1">
																<label class="control-label input-label p-0"
																	   style="margin-top:15px;">{{
																	$t('booking.child') }}</label>
															</div>
															<div class="col-md-5 p-0">
																<AppInput name="child_count_tour" input-style="normal" type="bigint"
																		  rules="min_value:0|max_value:99"
																		  v-model="entry.child_count_tour">
																	<template slot="append">
                                    <span style="margin: auto" class="app-text-bold">{{
                                        $t('common.people_count_icon')
                                      }}</span>
																	</template>
																</AppInput>
															</div>
														</div>
														<div class="row col-md-6">
															<div class="col-md-5-5 pl-0 p-t-15">
																<AppDatePicker name="start_date"
																			   :label="$t('booking.booking_date')"
																			   input-style="normal"
																				 @input="(value) => {schedules[0].date = value}"
																			   v-model="entry.start_date"/>
															</div>
															<div class="col-md-1 p-0 m-0 p-t-15">
																<div class="form-group app-form-group b-b-0 pb-0">
																	<label class="input-label control-label p-0 p-r-5 visible-none">
																		none
																	</label>
																	<div class="form-group m-b-0 p-0 text-center"
																		 style="height: 50px; line-height: 50px">
																		～
																	</div>
																</div>
															</div>
															<div class="col-md-5-5 pr-0 p-t-15">
																<AppDatePicker name="end_date" label=" "
																			   input-style="normal"
																				 @input="(value) => {if (schedules.length > 1) schedules[schedules.length - 1].date = value}"
																				 v-model="entry.end_date"/>
															</div>
														</div>
													</div>
												</div>
												<div class=" w-100">
													<div class="col-md-12 pl-2" style="margin-top:30px;">
														<ul class="nav nav-tabs nav-tabs-fillup d-none d-md-flex d-lg-flex d-xl-flex"
															data-init-reponsive-tabs="dropdownfx">
															<li class="nav-item">
																<a class="active show" data-toggle="tab" href="#tabs-1"
																   role="tab">
																	<span>{{ $t('tour_order_template.tourname_name') }}</span>
																</a>
															</li>

															<li class="nav-item">
																<a data-toggle="tab" href="#tabs-2" role="tab">
																	<span>{{ $t('tour_order_template.tourname_concept') }}</span>
																</a>
															</li>

															<li class="nav-item">
																<a data-toggle="tab" href="#tabs-3" role="tab">
																	<span>{{ $t('tour_order_template.schedule_templates') }}</span>
																</a>
															</li>

															<li class="nav-item">
																<a data-toggle="tab" href="#tabs-4" role="tab">
																	<span>{{ $t('tour_order_template.budgets') }}</span>
																</a>
															</li>

															<li class="nav-item">
																<a data-toggle="tab" href="#tabs-5" role="tab">
																	<span>{{ $t('tour_order_template.note') }}</span>
																</a>
															</li>

															<li class="nav-item">
																<a data-toggle="tab" href="#tabs-6" role="tab">
																	<span>{{ $t('tour_order_template.contact') }}</span>
																</a>
															</li>
														</ul>
														<div class="tab-content">
															<div class="tab-pane active" id="tabs-1" role="tabpanel">
																<AppInput
																		type="string"
																		name="tourname_name"
																		:label="$t('tour_order_template.tourname_name_name')"
																		input-style="normal"
																		v-model="entry.tour_order.tourname_name"
																/>

																<AppText
																		type="text"
																		name="tourname_explain"
																		:label="$t('tour_order_template.tourname_name_explain')"
																		input-style="normal"
																		v-model="entry.tour_order.tourname_explain"
																/>

																<div class="form-group">
																	<div class="form-group">
																		<label class="input-label control-label p-0 p-r-5">
																			{{ $t('tour_order_template.tourname_image')
																			}}
																		</label>
																	</div>

																	<div class="form-group">
																		<img
																				:height="tourNameImage.height + 'px'"
																				:width="tourNameImage.width + 'px'"
																				v-if="entry.tour_order.tourname_image_preview != '' && entry.tour_order.tourname_image_preview"
																				:src="entry.tour_order.tourname_image_preview"
																		/>
																		<div
																				v-if="entry.tour_order.tourname_image_preview != '' && entry.tour_order.tourname_image_preview"
																				class="btn-tool delete remove-image"
																				@click="removeImage()">
																		</div>

																		<input
																				class="form-control"
																				accept="image/jpg,image/jpeg,image/png"
																				type="file"
																				id="tourname_image"
																				ref="tourname_image"
																				@change="uploadImage($event, 'tourname_image')"
																		/>
																	</div>
																</div>
															</div>

															<div class="tab-pane" id="tabs-2" role="tabpanel">
																<div class="row">
																	<table class="table dataTable app-table no-footer"
																		   style="border: solid 1px #ccc;">
																		<tbody>
																		<template
																				v-for="(concept, index_concept) in concepts">
																			<tr>
																				<td style="width: 30%">
																					<AppInput
																							input-style="normal"
																							boundary-class="m-b-0 p-b-0"
																							type="string"
																							name="concepts_key"

																							v-model="concepts[index_concept].key"
																					/>
																				</td>
																				<td style="width: 60%">
																					<AppText
																							textarea-style="height: 50px"
																							boundary-class="m-b-0 p-b-0"
																							input-style="normal"
																							type="text"
																							name="concepts_value"
																							v-model="concepts[index_concept].value"
																					></AppText>
																				</td>
																				<td style="width: 10%; text-align: center;">
																					<button
																							style=" margin-top: 5px"
																							type="button"
																							v-if="concepts.length > 1"
																							class="btn-tool delete"
																							@click="removeConcept('concepts', index_concept)">
																					</button>
																				</td>
																			</tr>
																		</template>
																		</tbody>
																	</table>

																	<div class="col-md-12 pl-0"
																		 style="margin-top: 5px;">
																		<button
																				@click="addConcept('concepts')"
																				type="button"
																				class="btn btn-add-row"
																		>
																			<i class="fa fa-plus"
																			   aria-hidden="true"></i>
																		</button>
																	</div>
																</div>
															</div>

															<div class="tab-pane" id="tabs-3" role="tabpanel">
																<Schedule
																		:schedule="schedules"
																		:offices="offices"
																		:validate="validate"
																		:checkFirst="checkFirst"
																		type_schedule="booking"
																		:order_templates="entry.tour_order"
																		screen_type="booking_create_edit"
																		:tour_order_template_id="entry.tour_order_template_id"
																/>
															</div>

															<div class="tab-pane" id="tabs-4" role="tabpanel">
																<Budget :order_templates="entry.tour_order"
																				:entryData="entry"
																				:meta="meta"
																				@changeData="updateData"
																		:buget="buget"></Budget>
															</div>

															<div class="tab-pane" id="tabs-5" role="tabpanel">
																<div class="row">
																	<table class="table dataTable app-table no-footer"
																		   style="border: solid 1px #ccc;">
																		<thead>
																		<tr>
																			<th></th>
																			<th></th>
																			<th></th>
																		</tr>
																		</thead>
																		<tbody>
																		<template
																				v-for="(note_item, index_note) in note">
																			<tr>
																				<td style="width: 20%">
																					<AppInput
																							input-style="normal"
																							type="string"
																							boundary-class="m-b-0 p-b-0"
																							name="concepts_key"
																							v-model="note[index_note].key"
																					/>
																				</td>

																				<td style="width: 74%">
																					<AppText
																							textarea-style="height: 50px"
																							input-style="normal"
																							boundary-class="m-b-0 p-b-0"
																							type="text"
																							name="concepts_value"
																							v-model="note[index_note].value"
																					></AppText>
																				</td>

																				<td style="width: 6%; text-align: center; vertical-align: center">
																					<button
																							type="button"
																							style="margin-top: 5px;"
																							v-if="note.length > 1"
																							class="btn-tool delete"
																							@click="removeConcept('note', index_note)">
																					</button>
																				</td>
																			</tr>
																		</template>
																		</tbody>
																	</table>

																	<div class="col-md-12 pl-0" style="margin-top:5px;">
																		<button
																				@click="addConcept('note')"
																				type="button"
																				class="btn btn-add-row"
																		>
																			<i class="fa fa-plus"
																			   aria-hidden="true"></i>
																		</button>
																	</div>
																</div>
															</div>

															<div class="tab-pane row" id="tabs-6" role="tabpanel">
																<AppText
																		type="text"
																		name="contact_content"
																		:label="$t('tour_order_template.contact_content')"
																		input-style="normal"
																		v-model="entry.tour_order.contact_content"
																/>

																<div class="form-group">
																	<div class="form-group">
																		<label class="input-label control-label p-0 p-r-5">
																			{{ $t('tour_order_template.contact_image')
																			}}
																		</label>
																	</div>

																	<div class="form-group">
																		<img
																				:height="contactImage.height + 'px'"
																				:width="contactImage.width + 'px'"
																				v-if="entry.tour_order.contact_image_preview != '' && entry.tour_order.contact_image_preview"
																				:src="entry.tour_order.contact_image_preview"
																		/>
																		<div
																				v-if="entry.tour_order.contact_image_preview != '' && entry.tour_order.contact_image_preview"
																				class="btn-tool delete remove-image"
																				@click="removeContactImage()">
																		</div>

																		<input
																				class="form-control"
																				accept="image/jpg,image/jpeg,image/png"
																				type="file"
																				id="contact_image"
																				ref="contact_image"
																				@change="uploadImage($event, 'contact_image')"
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div class="col-md-12 m-t-20">
													<label class="control-label input-label m-t-10">
														{{ $t('booking.status_check') }}
													</label>
												</div>
												<div class="w-100" style="padding: 8px;">
													<div class="col-md-12 p-t-15"
														 style="border: 1px solid grey; margin-top: 10px !important;">
														<div class="row" v-for="(status_check, index) in status_check_list"
															 :key="index">
															<div class="col-md-2">
																<label class="col-md-12 input-label control-label p-0 p-r-5">{{
																	status_check.name }}</label>
																<button class="col-md-12" type="button" style="min-width: auto !important;"
																		@click="submitInformation(status_check.key)"
																		:class="['btn inline pull-right', 'btn-complete']">
																	{{ status_check.button }}
																</button>
															</div>
															<div class="col-md-2">
																<AppInput
																		type="string"
																		name="recognizer"
																		:label="$t('booking.recognizer')"
																		input-style="normal"
																		:disabled="true"
																		v-model="entry[status_check.key].user_name"
																/>
															</div>
															<div class="col-md-3">
																<AppInput
																		type="string"
																		name="recognition_date"
																		:label="$t('booking.recognition_date')"
																		input-style="normal"
																		:disabled="true"
																		v-model="entry[status_check.key].date"
																/>
															</div>
															<div class="col-md-5">
																<AppInput
																		type="string"
																		name="note"
																		:label="$t('booking.note')"
																		input-style="normal"
																		v-model="entry[status_check.key].note"
																/>
															</div>
														</div>
														<div class="row">
															<div class="col-md-2">
																<AppSelect name="payment_status" input-style="normal"
																		   :options-data="meta.payment_status_select"
																		   :options="{containerCssClass: 'z-index-0', placeholder: $t('booking.payment_status')}"
																		   :label="$t('booking.payment_status')"
																		   v-model="entry.payment_status"/>
															</div>
															<div class="col-md-2">
																<AppSelect name="user_confirmed_id" input-style="normal"
																		   :options-data="meta.admin_select"
																		   :options="{containerCssClass: 'z-index-0', placeholder: $t('booking.payment_confirm_admin_id')}"
																		   :label="$t('booking.payment_confirm_admin_id')"
																		   v-model="entry.user_confirmed_id"/>
															</div>
															<div class="col-md-3">
																<AppDatePicker name="application_deadline"
																			   input-style="normal"
																			   :label="$t('booking.payment_date')"
																			   v-model="entry.application_deadline"/>
															</div>
															<div class="col-md-5">
																<AppDatePicker name="payment_deadline"
																			   :label="$t('booking.payment_deadline')"
																			   input-style="normal"
																			   v-model="entry.payment_deadline"/>
															</div>
															<div class="col-md-2">
																<AppSelect name="status" input-style="normal"
																		   :options-data="meta.status_select"
																		   :options="{containerCssClass: 'z-index-0', placeholder: $t('booking.status')}"
																		   :label="$t('booking.status')"
																		   v-model="entry.status"/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div class="col-md-12 text-center m-t-20">
											<button type="button" style="color: #000000;"
													@click="entry.input_tour_plan_review_status = 2"
													:class="['btn btn-secondary btn-cons btn-modal-cancel m-r-5']">
												{{ $t('common.completed') }}
											</button>
											<button type="button" @click="entry.input_tour_plan_review_status = 1"
													:class="['btn btn-warning']" style="color: #000000;">
												{{ $t('common.draft') }}
											</button>
											<a v-if="idEditing && entry.tour_order_template_id" target="_blank" :href="entry.tour_order.public_url" >
												<button type="button"
														:class="['btn btn-success m-l-5']" style="color: #000000;">
												  {{ $t('booking.preview') }}
												</button>
											</a>
										</div>
									</div>
									<div :class="['col-md-12 tour-order-title row m-t-20' , {'tour-order-completed' : (entry.final_information_review_status == 2)}]"
											@click="content_4 = !content_4">
										<label class="control-label input-label p-0" style="margin-left:10px;">
											{{ $t('booking.4_final_information') }}
										</label>
										<span class="float-right"><i
												:class="['fa' , content_4 ? 'fa-caret-down' : 'fa-caret-up']"></i></span>
									</div>
									<div :class="['row', {'display-none' : !content_4}]">
										<div class="row" style="display: contents;">
											<div class="col-md-12 p-l-15 m-t-20">
												<input type="checkbox" class="m-r-10" value="1" v-model="entry.is_receive_info"/>
												<label class="control-label input-label p-0 m-l-10">{{ $t('booking.hope_to_mail') }}</label>
											</div>
											<div class=" w-100" style="padding:3px 8px 3px 3px;">
												<div class="col-md-12 pl-2 m-b-20" style="margin-top:30px; width: 100%;">
													<ul class="nav nav-tabs nav-tabs-fillup d-none d-md-flex d-lg-flex d-xl-flex"  data-init-reponsive-tabSchedules="dropdownfx">
														<li class="nav-item">
															<a class="active show" data-toggle="tab" href="#tabSchedules-1" role="tab">
																<span>{{ $t('tour_order_template.tourname_name') }}</span>
															</a>
														</li>

														<li class="nav-item">
															<a data-toggle="tab" href="#tabSchedules-2" role="tab">
																<span>{{ $t('tour_order_template.tourname_concept') }}</span>
															</a>
														</li>

														<li class="nav-item">
															<a data-toggle="tab" href="#tabSchedules-3" role="tab">
																<span>{{ $t('tour_order_template.schedule_templates') }}</span>
															</a>
														</li>

														<li class="nav-item">
															<a  data-toggle="tab" href="#tabSchedules-4" role="tab">
																<span>{{ $t('booking.add_information') }}</span>
															</a>
														</li>

														<li class="nav-item">
															<a  data-toggle="tab" href="#tabSchedules-5" role="tab">
																<span>{{ $t('tour_order_template.note') }}</span>
															</a>
														</li>

														<li class="nav-item">
															<a  data-toggle="tab" href="#tabSchedules-6" role="tab">
																<span>{{ $t('tour_order_template.contact') }}</span>
															</a>
														</li>
													</ul>
													<div class="tab-content">
														<div class="tab-pane active" id="tabSchedules-1" role="tabpanel">
															<AppInput
																	type="string"
																	name="tourname_name"
																	:label="$t('tour_order_template.tourname_name_name')"
																	input-style="normal"
																	v-model="entry.tour_order.tour_name_user"
															/>

															<AppText
																	type="text"
																	name="tourname_explain"
																	:label="$t('tour_order_template.tourname_name_explain')"
																	input-style="normal"
																	v-model="entry.tour_order.tour_explain_user"
															/>

															<div class="form-group">
																<div class="form-group">
																	<label class="input-label control-label p-0 p-r-5">
																		{{ $t('tour_order_template.tourname_image') }}
																	</label>
																</div>

																<div class="form-group">
																	<img
																			:height="tourImageUser.height + 'px'"
																			:width="tourImageUser.width + 'px'"
																			v-if="entry.tour_order.tour_image_user && entry.tour_order.tour_image_user != ''"
																			:src="entry.tour_order.tour_image_user"
																	/>
																	<div
																			v-if="entry.tour_order.tour_image_user && entry.tour_order.tour_image_user != ''"
																			class="btn-tool delete remove-image"
																			@click="removeImageUser()">
																	</div>

																	<input
																			class="form-control"
																			accept="image/jpg,image/jpeg,image/png"
																			type="file"
																			id="tourname_image_user"
																			ref="tourname_image_user"
																			@change="uploadImageUser($event, 'tourname_image')"
																	/>
																</div>
															</div>
														</div>

														<div class="tab-pane" id="tabSchedules-2" role="tabpanel">
															<div class="row">
																<table class="table dataTable app-table no-footer" style="border: solid 1px #ccc;">
																	<tbody>
																	<template v-for="(concept, index_concept) in concept_user">
																		<tr>
																			<td style="width: 30%">
																				<AppInput
																						input-style="normal"
																						boundary-class="m-b-0 p-b-0"
																						type="string"
																						name="concepts_user_key"
																						v-model="concept_user[index_concept].key"
																				/>
																			</td>
																			<td style="width: 60%">
																				<AppText
																						textarea-style="height: 50px"
																						boundary-class="m-b-0 p-b-0"
																						input-style="normal"
																						type="text"
																						name="concepts_user_value"
																						v-model="concept_user[index_concept].value"
																				></AppText>
																			</td>
																			<td style="width: 10%; text-align: center;">
																				<button
																						style=" margin-top: 5px"
																						type="button"
																						v-if="concept_user.length > 1"
																						class="btn-tool delete"
																						@click="removeConcept('concept_user', index_concept)">
																				</button>
																			</td>
																		</tr>
																	</template>
																	</tbody>
																</table>

																<div class="col-md-12 pl-0" style="margin-top: 5px;">
																	<button
																			@click="addConcept('concept_user')"
																			type="button"
																			class="btn btn-add-row"
																	>
																		<i class="fa fa-plus" aria-hidden="true"></i>
																	</button>
																</div>
															</div>
														</div>

														<div class="tab-pane" id="tabSchedules-3" role="tabpanel">
															<Schedule
																	:schedule="userSchedules"
																	:userSchedules="userSchedules"
																	:offices="offices"
																	:validate="validate"
																	:checkFirst="checkFirst"
																	type_schedule="user"
																	:order_templates="entry.tour_order"
																	screen_type="booking_create_edit"
																	:tour_order_template_id="entry.tour_order_template_id"
																	@action="changeUserSchedule"
															/>
														</div>

														<div class="tab-pane" id="tabSchedules-4" role="tabpanel">
															<div class="col-md-12 row">
																<div class="col-md-12" style="margin-bottom:10px">
																	<label class="control-label input-label p-0" style="margin-left:10px;">
																		{{ $t('booking.introduction_facilities') }}
																	</label>
																</div>
																<div class="col-md-12">
																	<div class="col-md-12">
																		<AppInput
																				type="string"
																				name="extra_info"
																				:label="$t('booking.extra_info')"
																				input-style="normal"
																				v-model="entry.tour_order.extra_name"
																		/>
																	</div>
																	<div class="form-group col-ms-12">
																		<div class="form-group m-b-0">
																			<label class="input-label control-label p-0 p-r-5">
																				{{ $t('booking.extra_image') }}
																			</label>
																		</div>
																		<div class="form-group">
																			<img
																					:height="extraImage.height + 'px'"
																					:width="extraImage.width + 'px'"
																					v-if="entry.tour_order.extra_image !== '' && entry.tour_order.extra_image"
																					:src="entry.tour_order.extra_image"
																			/>
																			<div
																					v-if="entry.tour_order.extra_image !== '' && entry.tour_order.extra_image"
																					class="btn-tool delete remove-image"
																					@click="removeExplanImage()">
																			</div>

																			<input
																					class="form-control"
																					accept="image/jpg,image/jpeg,image/png"
																					type="file"
																					id="extra_image"
																					ref="extra_image"
																					@change="uploadImage($event, 'extra_image')"
																			/>
																		</div>
																	</div>
																</div>
																<div class="w-100" style="padding: 7px">
																	<div class="row col-md-12 m-b-20" v-for="(explan, index) in extra_info" :key="index">
																		<div class="col-md-12 m-t-20 m-b-10" style="padding-left: 0!important;">
																			<label class="control-label input-label p-0">
																				{{ explan.key }}
																			</label>
																		</div>
																		<div class="col-md-12 row content-border padding-10">
																			<template v-for="(detail_explan, index_explan) in explan.value" >
																				<div style="border-style: none none double none; width: 100%" class="p-3 mb-4">
																					<div class="d-flex justify-content-between w-100 mr-4">
																						<div class="d-flex justify-content-between w-75">
																							<AppInput
																									type="string"
																									name="name"
																									:label="'Day' + (index_explan + 1)"
																									input-style="normal"
																									v-model="detail_explan.name"
																							/>
																							<AppInput
																									type="string"
																									name="content"
																									:label="$t('booking.extra_info')"
																									:placeholder="detail_explan.content_placeholder"
																									input-style="normal"
																									v-model="detail_explan.content"
																							/>
																						</div>
																						<button
																								v-if=" explan.value.length > 1"
																								@click="removeExplanValue('explain', index, index_explan)"
																								type="button"
																								class="btn-tool delete mt-4"
																								style="margin-right: 33px;"
																						></button>
																					</div>
																					<div>
																						<div class="dataTables_wrapper no-footer bg-white">
																							<div class="sm-m-b-15">
																								<table class="table dataTable app-table no-footer table_tab3"
																									   style="border: solid 1px #e4e4e4; border-bottom:  none !important;">
																									<thead>
																									<tr>
																										<th class="min-w-100">{{ detail_explan.time_label }}</th>
																										<th class="min-w-150">{{ detail_explan.location_label }}</th>
																										<th class="min-w-100">{{ detail_explan.explan_label }}</th>
																										<th></th>
																									</tr>
																									</thead>
																									<tbody>
																									<template v-for="(item, index_item) in detail_explan.items">
																										<tr>
																											<td class="text-center">
																												<VueTimepicker v-if="index === 'schedule_day_explan'"
																															   input-style="normal"
																															   name="time_schedule"
																															   hour-label="時"
																															   minute-label="分"
																															   v-model="extra_info[index].value[index_explan].items[index_item].time"
																												/>
																												<AppInput v-else
																														  type="string"
																														  name="time[]"
																														  input-style="normal"
																															boundaryClass="m-b-0 p-0"
																														  v-model="extra_info[index].value[index_explan].items[index_item].time"
																												/>
																											</td>
																											<td class="text-center">
																												<AppInput
																														type="string"
																														name="location[]"
																														input-style="normal"
																														boundaryClass="m-b-0 p-0"
																														v-model="extra_info[index].value[index_explan].items[index_item].location"
																												/>
																											</td>
																											<td>
																												<AppInput
																														type="string"
																														name="explan[]"
																														input-style="normal"
																														boundaryClass="m-b-0 p-0"
																														v-model="extra_info[index].value[index_explan].items[index_item].explan"
																												/>
																											</td>

																											<td>
																												<button
																														v-if="detail_explan.items.length > 1"
																														type="button"
																														class="btn-tool delete"
																														@click="removeExplanValue('explain_item', index, index_explan, index_item)"
																												></button>
																											</td>
																										</tr>
																									</template>
																									</tbody>
																								</table>
																							</div>
																						</div>
																					</div>
																					<div class="col-md-12" style="margin-bottom:20px;margin-top:5px;padding-left: 0">
																						<button
																								@click="addExplain('explain_item', index, index_explan)"
																								type="button"
																								class="btn btn-add-row"
																						>
																							<i class="fa fa-plus" aria-hidden="true"></i>
																						</button>
																					</div>
																				</div>
																			</template>
																			<div class="col-md-12 pl-3" style="text-align:center;margin-bottom:20px;">
																				<button
																						@click="addExplain('explain', index)"
																						type="button"
																						class="btn btn-create"
																				>
																					<i class="fa fa-plus" aria-hidden="true">{{ $t('booking.to_add') }}</i>
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>

														<div class="tab-pane" id="tabSchedules-5" role="tabpanel">
															<div class="row">
																<table class="table dataTable app-table no-footer" style="border: solid 1px #ccc;">
																	<thead>
																	<tr>
																		<th></th>
																		<th></th>
																		<th></th>
																	</tr>
																	</thead>
																	<tbody>
																	<template v-for="(note_item, index_note) in note_user">
																		<tr>
																			<td style="width: 20%">
																				<AppInput
																						input-style="normal"
																						type="string"
																						boundary-class="m-b-0 p-b-0"
																						name="note_user_value"
																						v-model="note_user[index_note].key"
																				/>
																			</td>

																			<td style="width: 74%">
																				<AppText
																						textarea-style="height: 50px"
																						input-style="normal"
																						boundary-class="m-b-0 p-b-0"
																						type="text"
																						name="note_user_value"
																						v-model="note_user[index_note].value"
																				></AppText>
																			</td>

																			<td style="width: 6%; text-align: center; vertical-align: center">
																				<button
																						type="button"
																						style="margin-top: 5px;"
																						v-if="note_user.length > 1"
																						class="btn-tool delete"
																						@click="removeConcept('note_user', index_note)">
																				</button>
																			</td>
																		</tr>
																	</template>
																	</tbody>
																</table>

																<div class="col-md-12 pl-0" style="margin-top:5px;">
																	<button
																			@click="addConcept('note_user')"
																			type="button"
																			class="btn btn-add-row"
																	>
																		<i class="fa fa-plus" aria-hidden="true"></i>
																	</button>
																</div>
															</div>
														</div>

														<div class="tab-pane row" id="tabSchedules-6" role="tabpanel">
															<AppText
																	type="text"
																	name="contact_content"
																	:label="$t('tour_order_template.contact_content')"
																	input-style="normal"
																	v-model="entry.tour_order.contact_content_user"
															/>

															<div class="form-group">
																<div class="form-group">
																	<label class="input-label control-label p-0 p-r-5">
																		{{ $t('tour_order_template.contact_image') }}
																	</label>
																</div>

																<div class="form-group">
																	<img
																			:height="contactImageUser.height + 'px'"
																			:width="contactImageUser.width + 'px'"
																			v-if="entry.tour_order.contact_image_user && entry.tour_order.contact_image_user != ''"
																			:src="entry.tour_order.contact_image_user"
																	/>
																	<div
																			v-if="entry.tour_order.contact_image_user && entry.tour_order.contact_image_user != ''"
																			class="btn-tool delete remove-image"
																			@click="removeContactImageUser()">
																	</div>

																	<input
																			class="form-control"
																			accept="image/jpg,image/jpeg,image/png"
																			type="file"
																			id="contact_image_user"
																			ref="contact_image_user"
																			@change="uploadImageUser($event, 'contact_image')"
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-12 text-center">
											<button type="button" style="color: #000000;"
													@click="entry.final_information_review_status = 2"
													:class="['btn btn-secondary btn-cons btn-modal-cancel m-r-5']">
												{{ $t('common.completed') }}
											</button>
											<button type="button" @click="entry.final_information_review_status = 1"
													:class="['btn btn-warning']" style="color: #000000;">
												{{ $t('common.draft') }}
											</button>
										</div>
									</div>
									<div class="m-t-20"></div>
									<div class="justify-content-end row m-t-20 m-b-20">
										<button @click="cancelCreateOrUpdate" type="button"
												:class="['btn btn-default btn-cons pull-right btn-modal-cancel']">
											{{ $t('common.cancel') }}
										</button>
										<button @click="storeOrUpdateBooking" type="submit"
												:class="['btn inline pull-right', 'btn-complete']">
											{{ idEditing ? $t('common.update_btn') : $t('common.save') }}
										</button>
									</div>
								</form>
							</ValidationObserver>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

  import B_CONST from './constants'
  import deepEqual from 'deep-equal'
  import Schedule from "@components/admin/tour_order_templates/Schedule";
  import Budget from "@components/admin/tour_order_templates/Budget";
  import FirstSupport from "@components/admin/bookings/_partials/FirstSupport";
  import HeadingSheet from "@components/admin/bookings/_partials/HeadingSheet";


  export default {
    components: {Schedule, Budget, FirstSupport, HeadingSheet},
    data() {
      const id = this.$route.params.id
	    const status_check_default = {
        id: null,
        user_name: null,
        date: null,
        note: ""
	    }
      const extra_json_data = {
        schedule_day_explan : {
					key: this.$t('booking.schedule_day_explan'),
					value: [
						{
							name: '',
							content: '',
							content_placeholder: '',
							time_label: this.$t('booking.time'),
							location_label: this.$t('booking.location'),
							explan_label: this.$t('booking.route'),
							items: [
								{
								time: '',
								location: '',
								explan: '',
								}
							],
						}
					]
			  },
        nearby_information: {
					key: this.$t('booking.nearby_information_title'),
					value: [
						{
						name: '',
						content: '',
						content_placeholder: this.$t('booking.nearby_information'),
						time_label: this.$t('booking.location'),
						location_label: this.$t('booking.basic_information'),
						explan_label: this.$t('booking.attention_item'),
						items: [
							{
							time: '',
							location: '',
							explan: '',
							}
						]
						}
					]
				},
        pay_locally: {
					key: this.$t('booking.pay_locally'),
					value: [
						{
							name: '',
							content: '',
							content_placeholder: this.$t('booking.content_paid'),
							time_label: this.$t('booking.payment_destination'),
							location_label: this.$t('booking.detail'),
							explan_label: this.$t('booking.attention_item'),
							items : [
								{
									time: '',
									location: '',
									explan: '',
								}
							]
						}
					]
				}
      }
      const tour_order_default = {
        name: '',
        price: '',
        edit_history: '',
        description: '',
        public_url: '',
        tourname_name: '',
        tourname_explain: '',
        tourname_image: '',
        tourname_image_preview: '',
        tourname_image_thumbnail: '',
        contact_content: '',
        contact_image: '',
        contact_image_preview: '',
        contact_image_thumbnail: '',
        prefecture_id: [],
        city_id: [],
        type: '',
        furusato_tour_city_id: '',
        furusato_code: '',
        furusato_from_site: '',
        extra_info: extra_json_data,
        tour_name_user: '',
        tour_explain_user: '',
        tour_image_user: '',
        contact_content_user: '',
        contact_image_user: '',
        extra_image: ''
      }
      const discuss_time_default = [
        {date: moment().format('YYYY/MM/DD'), time: 1},
        {date: moment().format('YYYY/MM/DD'), time: 1},
        {date: moment().format('YYYY/MM/DD'), time: 1},
      ]
      const initData = {
        status: 1,
        payment_status: 1,
        proposal_status: 1,
        invoice_status: 1,
        discuss_method: 0,
        admin_id: this.$auth.user().id.toString(),
        discuss_time: discuss_time_default,
        tour_order_template_id: null,
        member_id: null,
        user_type: null,
        adult_count_tour: "",
        child_count_tour: "",
        adult_count: "",
        child_count: "",
        admin: this.$auth.user(),
        member: {
          active: 1,
          sei: "",
          mei: ""
        },
        note: '',
        tour_type: 1,
        type: 1,
        furusato_tour_city_id: "",
        furusato_code: "",
        furusato_from_site: "",
        price: 0,
        extra_info: extra_json_data,
        payment_deadline: "",
        user_confirmed_id: "",
        application_deadline: "",
        hearing_first_time: "",
        hearing_area: "",
        hearing_room_allocation: "",
        hearing_meal_note: "",
        hearing_tour_note: "",
        hearing_other_note: "",
        furusato_nozei_product_note: "",
        information_create_plan: status_check_default,
        information_verify_plan: status_check_default,
        information_draft_booking: status_check_default,
        information_booking: status_check_default,
        first_time_support_review_status: 1,
        input_hearing_sheet_review_status: 1,
        input_tour_plan_review_status: 1,
        final_information_review_status: 1,
        tour_order: tour_order_default,
				budget_remark: this.$t('booking.budget_remark_default'),
				budget_remark_status: 1
      }
      const status_check_data = [
        // {
        //   key: 'information_create_plan',
        //   name : this.$t('booking.planing_document_create'),
        //   button: this.$t('booking.recognize'),
        //   user_name: '',
        //   date: '',
        //   note: ''
        // },
        {
          key: 'information_verify_plan',
          name : this.$t('booking.proposal_approval'),
          button: this.$t('booking.recognize'),
          user_name: '',
          date: '',
          note: ''
        },
        {
          key: 'information_draft_booking',
          name : this.$t('booking.temporary_reservation'),
          button: this.$t('booking.recognize'),
          user_name: '',
          date: '',
          note: ''
        },
        {
          key: 'information_booking',
          name : this.$t('booking.book_reservation'),
          button: this.$t('booking.completed'),
          user_name: '',
          date: '',
          note: ''
        }
      ]
      const concepts_default = [
      {
        key: "旅の目的",
        value: '',
      },
      {
        key: "主な内容",
        value: '',
      },
      {
        key: "楽しみ方",
        value: '',
      },
      {
        key: "ご要望など",
        value: '',
      },
      ]
      const budget_default = [
          {
            key: "交通",
            value: [
              {
                name: "",
                quantity: "",
                price: "",
              }
            ],
          },
          {
            key: "宿泊",
            value: [
              {
                name: "",
                quantity: "",
                price: "",
              }
            ],
          },
          {
            key: "飲食",
            value: [
              {
                name: "",
                quantity: "",
                price: "",
              }
            ],
          },
          {
            key: "ガイド",
            value: [
              {
                name: "",
                quantity: "",
                price: "",
              }
            ],
          },
          {
            key: "手数料",
            value: [
              {
                name: "",
                quantity: "",
                price: "",
              }
            ],
          }
        ]
      const note_default = [
          {
            key: "服装",
            value: '',
          },
          {
            key: "Wi-Fi",
            value: '',
          },
          {
            key: "お申し込み",
            value: '',
          },
          {
            key: "手数料",
            value: '',
          },
        ]
      const schedule_default = [
        {
          title: "",
          date: "",
          description: "",
          detail: [
            {
              time_schedule: "",
              office_id: 0,
              plan_id: 0,
              office_name: "",
              plan_name: "",
              description: "",
              image: "",
              map_url: "",
              phone: "",
            }
          ]
        }
      ]
			const image_default= {
        height:300,
        width:400
      }
      const image_default_square= {
        height:550,
        width:550
      }
      return {
        meta: {
          discuss_method_select: B_CONST.DISCUSS_METHOD_SELECT,
          discuss_time_select: B_CONST.DISCUSS_TIME_SELECT,
          payment_status_select: B_CONST.PAYMENT_STATUS_SELECT,
          proposal_status_select: B_CONST.PROPOSAL_STATUS_SELECT,
          invoice_status_select: B_CONST.INVOICE_STATUS_SELECT,
          status_select: B_CONST.STATUS_SELECT,
          member_select: [],
          tour_orders_template_select: [],
          admin_select: [],
          money_receive_email: '',
          member_id: '',
          user_type: [],
        },
        idEditing: id,
        entry: initData,
        freezeEntry: initData,
        content_1: true,
        content_2: true,
        content_3: true,
        content_4: true,
        budget_default: budget_default,
        buget: budget_default,
        concepts: concepts_default,
        concepts_default: concepts_default,
        note: note_default,
        note_default: note_default,
        schedules: _.cloneDeep(schedule_default),
        old_schedules: _.cloneDeep(schedule_default),
        schedule_default: _.cloneDeep(schedule_default),
        userSchedules: _.cloneDeep(schedule_default),
        extra_info:  _.cloneDeep(extra_json_data),
        extra_info_default: _.cloneDeep(extra_json_data),
        budget_user: [
          {
            key: "交通",
            value: [
              {
                name: "",
                quantity: "",
                price: "",
              }
            ],
          },
          {
            key: "宿泊",
            value: [
              {
                name: "",
                quantity: "",
                price: "",
              }
            ],
          },
          {
            key: "飲食",
            value: [
              {
                name: "",
                quantity: "",
                price: "",
              }
            ],
          },
          {
            key: "ガイド",
            value: [
              {
                name: "",
                quantity: "",
                price: "",
              }
            ],
          },
          {
            key: "手数料",
            value: [
              {
                name: "",
                quantity: "",
                price: "",
              }
            ],
          }
        ],
        concept_user: [
          {
            key: "旅の目的",
            value: '',
          },
          {
            key: "主な内容",
            value: '',
          },
          {
            key: "楽しみ方",
            value: '',
          },
          {
            key: "ご要望など",
            value: '',
          },
        ],
        note_user: [
          {
            key: "服装",
            value: '',
          },
          {
            key: "Wi-Fi",
            value: '',
          },
          {
            key: "お申し込み",
            value: '',
          },
          {
            key: "手数料",
            value: '',
          },
        ],
        validate: "",
        offices: [],
        areas: [
          {
            order_no: 1,
            prefecture_id: 0,
            city_id: 0,
            cities: [],
          }
        ],
        checkFirst: true,
        status_check_list : status_check_data,
        status_check_default : status_check_default,
        tour_order_default : tour_order_default,
        discuss_time_default : discuss_time_default,
        extra_json_data : extra_json_data,
        options_prefecture: [],
        options_cities: [],
        tour_types: [
          {id : 1, name: this.$t('tour_order_template.normal_type')},
          {id : 2, name: this.$t('tour_order_template.furusato_type')}
        ],
        user_tour_types: [
          {id : 1, name: this.$t('tour_order_template.normal_type')},
          {id : 2, name: this.$t('tour_order_template.furusato_type')}
        ],
        totalChange: 0,
		    countChange: 0,
        image_default: _.cloneDeep(image_default),
        image_default_square: _.cloneDeep(image_default_square),
        image:{
          height:'',
          width:''
        },
        tourNameImage: _.cloneDeep(image_default),
        contactImage: _.cloneDeep(image_default),
        tourImageUser: _.cloneDeep(image_default),
        contactImageUser: _.cloneDeep(image_default),
        extraImage: _.cloneDeep(image_default),
      }
    },
    computed: {
      isFormDirty() {
        return Object.keys(this.entry).some(key => this.entry[key].dirty);
      },
      computedEntry() {
        var price = null;
        if (this.entry.tour_order_template_id && (this.freezeEntry.tour_order_template_id != this.entry.tour_order_template_id)) {
          _.forEach(this.meta.tour_orders_template_select, to => {
            if (to.id == this.entry.tour_order_template_id) {
              price = to.price;
              return;
            }
          })
        } else if (this.entry.tour_order) {
          price = this.entry.tour_order.price
        }
        return {
          price: this.formatNumber(price)
        }
      },
			show_price() {
        let totalPrice = this.entry.tour_order.price;
        if (this.entry.is_receive_info) {
          totalPrice += parseInt(this.meta.money_receive_email)
        }
        return this.formatPrice(totalPrice)
			}
    },
    async created() {
      await this.getFullCities()
    },
    async mounted() {
      this.getAlias();
      await this.getOfficeData()
      this.$request.get(this.$consts.API.ADMIN.UI_HELPER_BOOKING).then(res => {
        if (!res.hasErrors()) {
          this.meta.member_select = res.data.members
          this.meta.admin_select = res.data.admins
          this.meta.money_receive_email = res.data.money_receive_email
        }
      })
      if (this.idEditing) {
		    this.checkFirst = false
        await this.getBooking();
        this.checkFirst = true
      } else {
        await this.getListTourOrder(1)
				this.status_check_list.forEach(status => {
					this.entry[status.key] = {
								date:null,
								id:null,
								note:"",
								user_name:null
					}
				})
      }
    },
    watch: {
      'entry.tour_order.type': function(value) {
        this.entry.tour_type = _.cloneDeep(value)
        if (this.checkFirst) {
          this.getListTourOrder(value)
        }
      },
      'entry.tour_order.tourname_name': function(value) {
        this.changeDataEntry('tour_name_user', value)
      },
      'entry.tour_order.tourname_explain': function(value) {
        this.changeDataEntry('tour_explain_user', value)
      },
      'entry.tour_order.tourname_image_preview': function(value) {
        this.changeDataEntry('tour_image_user', value)
      },
      'entry.tour_order.contact_content': function(value) {
        this.changeDataEntry('contact_content_user', value)
      },
      'entry.tour_order.contact_image': function(value) {
        this.changeDataEntry('contact_image_user', value)
      },
      'concepts': {
        handler(val, oldVal) {
          if (this.checkFirst) {
            this.concept_user = _.cloneDeep(val)
          }
        },
        deep: true
      },
      'note': {
        handler(val, oldVal) {
          if (this.checkFirst) {
              this.note_user = _.cloneDeep(val)
          }
        },
        deep: true
      },
      'schedules': {
          handler(val, oldVal) {
            if (this.checkFirst) {
              if (this.checkSchedule(val, this.old_schedules)) {
                this.userSchedules = _.cloneDeep(val)
                this.old_schedules = _.cloneDeep(val)
								this.entry.start_date = _.cloneDeep(val[0].date)
                if (val.length > 1) {
                  this.entry.end_date = _.cloneDeep(val[val.length - 1].date)
                }
              }
            }
          },
        deep: true
	    }
    },
    methods: {
      changeDataEntry(target, source) {
        if (this.checkFirst) {
          this.entry.tour_order[target] = _.cloneDeep(source);
        }
	  },
      changeUserData(field) {
        if (this.checkFirst) {
          switch (field) {
            case 'concepts':
              this.concept_user = _.cloneDeep(this.concepts);
              break;
            case 'note':
              this.note_user = _.cloneDeep(this.note);
              break;
          }
        }
	  },
		changeUserSchedule(schedule) {
			if (schedule.type === "add") {
			let data = this.userSchedules
			data.push({
						date: "",
						description: "",
						detail: [
							schedule.data
						]
				})
				this.userSchedules = data
			}
	  },
      storeOrUpdateBooking() {
        this.$refs.form_create_or_update.validate().then(async (result) => {
          if (!result) {
            this.scrollToValidateError();
            return false;
          }
          if (this.freezeEntry.tour_order_template_id && this.freezeEntry.tour_order_template_id != this.entry.tour_order_template_id) {
            this.$refs.modal_confirm_update.show();
            return;
          }
          this.storeOrUpdateBookingRequest();
        });
      },
      async storeOrUpdateBookingRequest() {
        var res = null;
        let data = this.entry
		    data.areas = this.areas
        data.tour_order = await this.convertDataTourOrder()
        if (this.idEditing) {
          res = await this.$request.patch(this.$consts.API.ADMIN.BOOKING_UPDATE(this.idEditing), data);
        } else {
          res = await this.$request.post(this.$consts.API.ADMIN.BOOKING_CREATE, data);
        }
        if (!res.hasErrors()) {
          this.$appNotice.success(this.idEditing ? this.$t('common.msg update ok') : this.$t('common.msg create ok'))
          this.redirectBack();
        } else {
          this.$appNotice.error(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          this.$refs.modal_confirm_update.hide();
        }
      },
      async convertDataTourOrder() {
        const areas = [];
        let index1 = 1

        this.areas.filter(function (area) {
          if (area.prefecture_id != 0) {
            areas.push({
              order_no: index1,
              prefecture_id: Number(area.prefecture_id),
              city_id: Number(area.city_id),
            });

            index1++;
          }
        });
        let budget = _.cloneDeep(this.buget)
        budget.map(buget => {
				  buget.value.map(val => {
				    if (val.price !== undefined && val.price !== null && val.price !== '' ) {
              val.price = val.price.replaceAll(',', '')
            }
						return val
					})
					return buget
				})

        return {
          name: this.entry.tour_order.name,
          prefecture_id: this.entry.tour_order.prefecture_id,
          edit_history: this.entry.tour_order.edit_history,
          city_id: this.entry.tour_order.city_id,
          price: this.entry.tour_order.price,
          description: this.entry.tour_order.description,
          public_url: this.entry.tour_order.public_url,
          tourname_name: this.entry.tour_order.tourname_name,
          tourname_explain: this.entry.tour_order.tourname_explain,
          tourname_image: this.entry.tour_order.tourname_image,
          tourname_image_preview: this.entry.tour_order.tourname_image_preview,
          tourname_image_thumbnail: this.entry.tour_order.tourname_image_thumbnail,
          concept: this.concepts,
          note: this.note,
          schedules: this.schedules,
          userSchedules: this.userSchedules,
          budget: budget,
          concept_user: this.concept_user,
          note_user: this.note_user,
          budget_user: this.budget_user,
          extra_info: this.extra_info,
          contact_content: this.entry.tour_order.contact_content,
          contact_image: this.entry.tour_order.contact_image,
          contact_image_preview: this.entry.tour_order.contact_image_preview,
          contact_image_thumbnail: this.entry.tour_order.contact_image_thumbnail,
          type: this.entry.tour_order.type,
          furusato_tour_city_id: this.entry.tour_order.furusato_tour_city_id,
          furusato_code: this.entry.tour_order.furusato_code,
          furusato_from_site: this.entry.tour_order.furusato_from_site,
          contact_content_user: this.entry.tour_order.contact_content_user,
          contact_image_user: this.entry.tour_order.contact_image_user,
          tour_name_user: this.entry.tour_order.tour_name_user,
          tour_explain_user: this.entry.tour_order.tour_explain_user,
          tour_image_user: this.entry.tour_order.tour_image_user,
          extra_name: this.entry.tour_order.extra_name,
          extra_image: this.entry.tour_order.extra_image,
          areas: areas,
          coupons: this.entry.coupons
        };
      },
      async getBooking() {
        const res = await this.$request.get(this.$consts.API.ADMIN.BOOKING_SHOW(this.idEditing))

        if (!res.hasErrors()) {
          let type = 1
          if (res.data.data.tour_order) {
            type = _.cloneDeep(res.data.data.tour_order.type)
		      }
          await this.getListTourOrder(type)
          if (res.data.data.tour_order_template) {
            await this.getTourDetail(res.data.data.tour_order_template.id)
          }

          this.entry = _.cloneDeep(res.data.data)
          this.freezeEntry = _.cloneDeep(this.entry)
          this.status_check_list.forEach(status_check => {
            if (res.data.data[status_check.key] === null) {
              this.entry[status_check.key] = _.cloneDeep(this.status_check_default)
						}
					})
					if (res.data.data.discuss_time === null) {
						this.entry.discuss_time = _.cloneDeep(this.discuss_time_default)
					}
					await this.getAreas(res.data.data.tour_order)
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }

        this.checkImageSize()
      },
      scrollToValidateError(timeout = 0) {
        this.$nextTick(() => {
          setTimeout(() => {
            var elToScroll = null;
            let elementsInErrors = document.getElementsByClassName('error');
            if (elementsInErrors && elementsInErrors.length > 0) {
              elToScroll = elementsInErrors[0];
            }
            if (elToScroll) {
              $('html, body').animate({
                scrollTop: $(elToScroll).offset().top - 200
              }, 500)
            }
          }, timeout);
        });
      },
      async getOfficeData() {
        let params = {};
        params[`filters[status][equal]`] = this.$consts.INPUT.ACTIVE;
        const res = await this.$request.get(this.$consts.API.ADMIN.OFFICES_LIST_NAME, params);

        if (!res.hasErrors()) {
          this.offices = res.data.data;
        }
      },
      redirectBack() {
        if (this.$store.state.setting.prevRouter.name === this.$consts.ROUTES.ADMIN.BOOKINGS) {
          this.$router.go(-1);
        } else {
          this.$router.pushByName(this.$consts.ROUTES.ADMIN.BOOKINGS);
        }
      },
      cancelCreateOrUpdate() {
        this.$refs.modal_confirm_cancel.show();
      },

	  	async getAreas(tour_order) {
        if (tour_order) {
          let cities = this.cities = tour_order.cities;
          let new_areas = [];
          let listPrefecture = [];
          cities.forEach(city => {
            listPrefecture.push(city.prefecture_id)
          })
          let params = {};
          params[`filters[prefecture_id]`] = listPrefecture.join(',');
          let listPre = await this.$request.get(this.$consts.API.ADMIN.TOUR_PREFECTURES_CITIES, params);

          for (var i = 0; i < cities.length; i++) {
            let newCites = []
            listPre.data.data.forEach(pre => {
              if (pre.id === cities[i].prefecture_id) newCites = pre.cities
            })
            newCites.push({
              id: 0,
              name: this.$t('common.no_select_all'),
            });
            new_areas.push({
              order_no: Number(i) + 1,
              prefecture_id: cities[i].prefecture_id,
              city_id: cities[i].id,
              cities: newCites,
            })
          }

          if (new_areas.length > 0) {
            this.areas = new_areas;
          }
          this.concepts = tour_order.concept;
          this.note = tour_order.note;
          this.buget = tour_order.budget;
          let extra_info_tmp = _.cloneDeep(this.extra_info_default);

          if (tour_order.extra_info !== null) {
            Object.keys(extra_info_tmp).forEach((extra, extra_key) => {
              extra_info_tmp[extra] = _.cloneDeep(tour_order.extra_info[extra])
            })
		  		}
          this.extra_info = _.cloneDeep(extra_info_tmp);

          if (tour_order.concept_user !== null) {
            this.concept_user = tour_order.concept_user;
          }
          if (tour_order.note_user !== null) {
            this.note_user = tour_order.note_user;
          }

          localStorage.setItem('SCHEDULE', JSON.stringify(tour_order.schedules));
          if (tour_order.schedules.length > 0) {
            var formatSchedules = _.cloneDeep(tour_order.schedules)
            _.forEach(formatSchedules, (schedule, index) => {
              let formatDate = schedule.date ? moment(schedule.date).format('YYYY/MM/DD') : "";
              formatSchedules[index].date = formatDate;
            });
            this.schedules = _.cloneDeep(formatSchedules);
            this.old_schedules = _.cloneDeep(formatSchedules);
          }
          if (tour_order.user_schedules.length > 0) {
            this.user_schedule = tour_order.user_schedules;
            var formatUserSchedules = _.cloneDeep(this.user_schedule)
            _.forEach(formatUserSchedules, (schedule, index) => {
              let formatUserDate = schedule.date ? moment(schedule.date).format('YYYY/MM/DD') : "";
              formatUserSchedules[index].date = formatUserDate;
            });
            this.userSchedules = _.cloneDeep(formatUserSchedules);
          }
      } else {
          let data = _.cloneDeep(this.tour_order_default)
		      data.type = _.cloneDeep(this.entry.tour_type)
          this.entry.tour_order = _.cloneDeep(this.tour_order_default)
          this.extra_info = _.cloneDeep(this.extra_json_data)
        }
      },
      async getListTourOrder(type) {
        const res = await this.$request.get(this.$consts.API.ADMIN.UI_HELPER_TOUR_ORDER, {type: type});
        if (!res.hasErrors()) {
          this.meta.tour_orders_template_select = _.cloneDeep(res.data.tour_orders)
        }
      },

      async getTourDetail(id) {
        if (!this.checkFirst) {
          return ;
        }
        if (id !== null) {
          const res = await this.$request.get(this.$consts.API.ADMIN.TOUR_ORDER_TEMPLATES_EDIT(id));
          if (!res.hasErrors()) {
            let order_templates = res.data.data;
            let prefectures = this.prefectures = order_templates.prefectures;
            let cities = this.cities = order_templates.cities;
            let prefecture_id = [];
            let city_id = [];
            let new_areas = [];
            let listPrefecture = [];
            cities.forEach(city => {
              listPrefecture.push(city.prefecture_id)
            })
            let params = {};
            params[`filters[prefecture_id]`] = listPrefecture.join(',');
            let listPre = await this.$request.get(this.$consts.API.ADMIN.TOUR_PREFECTURES_CITIES, params);

            for (var i = 0; i < cities.length; i++) {
              let newCites = []
              listPre.data.data.forEach(pre => {
                if (pre.id === cities[i].prefecture_id) newCites = pre.cities
              })
              newCites.push({
                id: 0,
                name: this.$t('common.no_select_all'),
              });
              if (!res.hasErrors()) {
                new_areas.push({
                  order_no: Number(i) + 1,
                  prefecture_id: cities[i].prefecture_id,
                  city_id: cities[i].id,
                  cities: newCites,
                });
              }
            }

            if (new_areas.length > 0) {
              this.areas = new_areas;
            }

            this.entry.tour_order = {
              name: order_templates.tourname_name,
              price: order_templates.price,
              edit_history: order_templates.edit_history,
              description: order_templates.description,
              public_url: order_templates.public_url,
              tourname_name: order_templates.tourname_name,
              tourname_explain: order_templates.tourname_explain,
              tourname_image: order_templates.tourname_image != null ? order_templates.tourname_image : '',
              tourname_image_preview: order_templates.tourname_image_preview != null ? order_templates.tourname_image_preview : '',
              tourname_image_thumbnail: order_templates.tourname_image_thumbnail != null ? order_templates.tourname_image_thumbnail : '',
              contact_content: order_templates.contact_content,
              contact_image: order_templates.contact_image != null ? order_templates.contact_image : '',
              contact_image_preview: order_templates.contact_image_preview != null ? order_templates.contact_image_preview : '',
              contact_image_thumbnail: order_templates.contact_image_thumbnail != null ? order_templates.contact_image_thumbnail : '',
              prefecture_id: prefecture_id,
              city_id: city_id,
              type: order_templates.type,
              furusato_tour_city_id: order_templates.furusato_tour_city_id,
              furusato_code: order_templates.furusato_code,
              furusato_from_site: order_templates.furusato_from_site,
              extra_info: order_templates.extra_info,
              tour_image_user: '',
              contact_image_user: '',
              extra_image: '',
            }
            await this.getListTourOrder(order_templates.type)

            this.concepts = order_templates.concept;
            this.note = order_templates.note;
            this.buget = order_templates.budget;
            this.entry.budget_remark = order_templates.budget_remark;
            this.entry.budget_remark_status = order_templates.budget_remark_status;
            localStorage.setItem('SCHEDULE', JSON.stringify(order_templates.schedules));
            var formatSchedules = _.cloneDeep(order_templates.schedules)
            _.forEach(formatSchedules, (schedule, index) => {
              let formatDate = schedule.date ? moment(schedule.date).format('YYYY/MM/DD') : "";
              formatSchedules[index].date = formatDate;
            });
            this.schedules = _.cloneDeep(formatSchedules);
          } else {
            this.$appNotice.error(res.data?.error_msg)
          }
        } else {
          this.prefectures = [];
          this.cities = [];
          this.areas = [
            {
              order_no: 1,
              prefecture_id: 0,
              city_id: 0,
              cities: [],
            }
          ];
          let data = _.cloneDeep(this.tour_order_default)
					data.type = _.cloneDeep(this.entry.tour_type)
          this.entry.tour_order = _.cloneDeep(data)

          this.concepts = _.cloneDeep(this.concepts_default);
          this.note = _.cloneDeep(this.note_default);
          this.buget =  _.cloneDeep(this.budget_default);
          localStorage.setItem('SCHEDULE', JSON.stringify(_.cloneDeep(this.schedule_default)));
          this.schedules = _.cloneDeep(this.schedule_default);
				}
        this.checkImageSize()
      },
      async submitInformation(action) {
        if (this.idEditing) {
          let params = {
            column: action,
            note: this.entry[action].note,
            date: moment().format('YYYY/MM/DD HH:mm:ss')
          };
          let res = await this.$request.post(this.$consts.API.ADMIN.BOOKING_INFORMATION(this.idEditing), params);
          if (!res.hasErrors()) {
            // this.$appNotice.success(this.idEditing ? this.$t('common.msg update ok') : this.$t('common.msg create ok'))
          } else {
            this.$appNotice.error(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
            return;
          }
        }
        let user = this.$auth.user()
        this.entry[action] = {
          id: user.id,
          user_name: user.name,
          date: moment().format('YYYY/MM/DD HH:mm:ss'),
          note: this.entry[action].note
        }
      },
      async getAlias()
      {
        const res = await this.$request.get(this.$consts.API.ADMIN.TOUR_PREFECTURES, {
          sortType: "asc",
          sortBy: "code",
        });

        if (!res.hasErrors()) {
          this.options_prefecture = res.data.data;
          this.options_prefecture.push({
            id: 0,
            name: this.$t('common.no_select_all'),
          });
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      },
      async changePrefecture(id, index)
      {
        this.areas[index].cities = [];

        if (id != null && id != 0) {
          let params = {
            sortType: "asc",
            sortBy: "code",
          };
          params[`filters[prefecture_id][equal]`] = id;
          const res = await this.$request.get(this.$consts.API.ADMIN.TOUR_CITIES, params);
          this.areas[index].prefecture_id = id;
          this.areas[index].cities = [];

          if (!res.hasErrors()) {
            this.areas[index].cities = res.data.data;

            if (this.areas[index].cities.length == 0) {
              this.areas[index].cities.push({
                id: 0,
                name: this.$t('common.no_select_all'),
              });
            }

            this.areas[index].city_id = res.data.data[0].id;
          } else {
            this.$appNotice.error(res.data?.error_msg)
          }
        } else if (id == 0) {
          this.areas[index].cities.push({
            id: 0,
            name: this.$t('common.no_select_all'),
          });

          this.areas[index].city_id = 0;
        }
      },
      changeCity(id, index) {
        this.areas[index].city_id = id;
        if (this.firstLoading) {
          this.firstLoading = false;
        }
      },
      addArea()
      {
        this.areas.push({
          order_no: this.areas.length,
          prefecture_id: 0,
          city_id:0,
          cities: [{
            id: 0,
            name: this.$t('common.no_select_all'),
          }],
        });
      },

      removeArea(index)
      {
        this.$refs.modal_delete_entry.show();

        this.delete = {
          name: "areas",
          key_1: index,
          key_2: "",
        }
      },
      async getFullCities() {
        const res = await this.$request.get(this.$consts.API.ADMIN.TOUR_FULL_CITIES, {});
        if (!res.hasErrors()) {
          this.options_cities = res.data.data;
        }
      },
      //nav tab
      async uploadImage(event, type, index1, index2)
      {
        const image = event.target.files[0];

        if (this.checkImage(image)) {
          this.$appNotice.error(this.$t('dialog.image_type'));

          if (type == 'tourname_image') {
            this.$refs.tourname_image.value = null;
          }

          if (type == 'contact_image') {
            this.$refs.contact_image.value = null;
          }

          if (type == 'extra_image') {
            this.$refs.extra_image.value = null;
          }

          return;
        }

        if ((image.size / 1048576) > 5) {
          if (type == 'tourname_image') {
            this.$refs.tourname_image.value = null;
          }

          if (type == 'contact_image') {
            this.$refs.contact_image.value = null;
          }
          if (type == 'extra_image') {
            this.$refs.extra_image.value = null;
          }
          this.$appNotice.error(this.$t('dialog.image_max', {size: ' 5MB'}));

          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = e =>{
          this.uploadImage1(e.target.result, type, index1, index2, image.size)
          let img = new Image();
          img.onload = () => {
            this.image.width = img.width;
            this.image.height = img.height;
            this.changeSize(img.width, img.height, type)
          }
          img.src = e.target.result;
        };
      },
      async changeSize(width, height, type) {
        if (type == 'tourname_image') {
          this.tourNameImage =  _.cloneDeep(this.image_default)
          this.tourImageUser =  _.cloneDeep(this.image_default)
          if (width === height) {
            this.tourNameImage =  _.cloneDeep(this.image_default_square)
            this.tourImageUser =  _.cloneDeep(this.image_default_square)
          }
        }

        if (type == 'contact_image') {
          this.contactImage = _.cloneDeep(this.image_default)
          this.contactImageUser = _.cloneDeep(this.image_default)
          if (width === height) {
            this.contactImage = _.cloneDeep(this.image_default_square)
            this.contactImageUser = _.cloneDeep(this.image_default_square)
          }
        }
        if (type == 'extra_image') {
          this.extraImage = _.cloneDeep(this.image_default)
          if (width === height) {
            this.extraImage = _.cloneDeep(this.image_default_square)
          }
        }
      },

      async uploadImage1(file, type, index1, index2, size)
      {
        let path="";

        if (type == "tourname_image") {
          path = "tours/names";
        }

        if (type == "contact_image") {
          path = "tours/contacts";
        }

        if (type == "schedules_image") {
          path = "tours/schedules";
        }

        if (type == "extra_image") {
          path = "tours/extra";
        }

        const res = await this.$request.post(this.$consts.API.ADMIN.UPLOAD_IMAGE, {
          image: file,
          size: size,
          path: path,
        });

        if (type == "tourname_image") {
          this.entry.tour_order.tourname_image = res.data.data;
          this.entry.tour_order.tourname_image_preview = res.data.extra.preview;
          this.entry.tour_order.tourname_image_thumbnail = res.data.extra.thumbnail;
        }

        if (type == "contact_image") {
          this.entry.tour_order.contact_image = res.data.data;
          this.entry.tour_order.contact_image_preview = res.data.extra.preview;
          this.entry.tour_order.contact_image_thumbnail = res.data.extra.thumbnail;
        }
        if (type == "extra_image") {
          this.entry.tour_order.extra_image = res.data.extra.preview;
        }
      },
      removeContactImage(){
        this.entry.tour_order.contact_image = '';
        this.entry.tour_order.contact_image_preview = '';
        this.entry.tour_order.contact_image_thumbnail = '';
        this.$refs.contact_image.value = null
      },
      removeImage(){
        this.entry.tour_order.tourname_image = '';
        this.entry.tour_order.tourname_image_preview = '';
        this.entry.tour_order.tourname_image_thumbnail = '';
        this.$refs.tourname_image.value = null
      },
      addConcept(type)
      {
        if (type == "concepts") {
          this.concepts.push({
            key: '',
            value: '',
          });
        }
        if (type == "concept_user") {
          this.concept_user.push({
            key: '',
            value: '',
          });
        }

        if (type == "note") {
          this.note.push({
            key: '',
            value: '',
          });
        }

        if (type == "note_user") {
          this.note_user.push({
            key: '',
            value: '',
          });
        }
      },

      async uploadImageUser(event, type, index1, index2)
      {
        const image = event.target.files[0];
        if (this.checkImage(image)) {
          this.$appNotice.error(this.$t('dialog.image_type'));

          if (type == 'tourname_image') {
            this.$refs.tourname_image_user.value = null;
          }

          if (type == 'contact_image') {
            this.$refs.contact_image_user.value = null;
          }

          return;
        }

        if ((image.size / 1048576) > 5) {
          if (type == 'tourname_image') {
            this.$refs.tourname_image_user.value = null;
          }

          if (type == 'contact_image') {
            this.$refs.contact_image_user.value = null;
          }
          this.$appNotice.error(this.$t('dialog.image_max', {size: ' 5MB'}));

          return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = async e => {
          this.uploadImageUser1(e.target.result, type, index1, index2, image.size, e.width)
          let img = new Image();
          img.onload = () => {
            this.image.width = img.width;
            this.image.height = img.height;
            this.changeSizeUser(img.width, img.height, type)
          }
          img.src = e.target.result;
        };
      },
			async changeSizeUser(width, height, type) {
        if (type == 'tourname_image') {
          this.tourImageUser = _.cloneDeep(this.image_default)
          if (width === height) {
            this.tourImageUser = _.cloneDeep(this.image_default_square)
          }
        }

        if (type == 'contact_image') {
          this.contactImageUser = _.cloneDeep(this.image_default)
          if (width === height) {
            this.contactImageUser = _.cloneDeep(this.image_default_square)
          }
        }
			},
      async uploadImageUser1(file, type, index1, index2, size)
      {
        let path="";

        if (type == "tourname_image") {
          path = "tours/names";
        }

        if (type == "contact_image") {
          path = "tours/contacts";
        }

        if (type == "schedules_image") {
          path = "tours/schedules";
        }

        if (type == "extra_image") {
          path = "tours/extra";
        }

        const res = await this.$request.post(this.$consts.API.ADMIN.UPLOAD_IMAGE, {
          image: file,
          size: size,
          path: path,
        });

        if (type == "tourname_image") {
          this.entry.tour_order.tour_image_user = res.data.extra.preview;
        }

        if (type == "contact_image") {
          this.entry.tour_order.contact_image_user = res.data.extra.preview;
        }
      },
      removeContactImageUser(){
        this.entry.tour_order.contact_image_user = '';
        this.$refs.contact_image_user.value = null
      },
      removeImageUser(){
        this.entry.tour_order.tour_image_user = '';
        this.$refs.tourname_image_user.value = null
      },
      removeConcept(type, index_item)
      {
        this.$refs.modal_delete_entry.show();

        this.delete = {
          name: type,
          key_1: index_item,
          key_2: "",
        }
      },
      addExplan(index) {
        let dataExplan = {}
        switch (index) {
          case 0: dataExplan = {
            name: '',
            content: '',
            content_placeholder: '',
            time: '',
            time_label: this.$t('booking.time'),
            location: '',
            location_label: this.$t('booking.location'),
            explan: '',
            explan_label: this.$t('booking.route'),
          }
            break;
          case 2: dataExplan = {
            name: '',
            content: '',
            content_placeholder: this.$t('booking.nearby_information'),
            time: '',
            time_label: this.$t('booking.location'),
            location: '',
            location_label: this.$t('booking.basic_information'),
            explan: '',
            explan_label: this.$t('booking.attention_item'),
          }
            break
          default : dataExplan ={
            name: '',
            content: '',
            content_placeholder: this.$t('booking.content_paid'),
            time: '',
            time_label: this.$t('booking.payment_destination'),
            location: '',
            location_label: this.$t('booking.detail'),
            explan: '',
            explan_label: this.$t('booking.attention_item'),
          }

        }
        this.extra_info[index].value.push(dataExplan)
      },
      removeExplanValue(type, index, index_explan, index_item) {
        this.$refs.modal_delete_entry.show();

        this.delete = {
          name: type,
          key_1: index,
          key_2: index_explan,
          key_3: index_item,
        }
      },
      removeExplanImage(){
        this.entry.tour_order.extra_image = '';
        this.$refs.extra_image.value = null
      },
      deleteEntrySubmit()
      {
        if (this.delete.name == "areas") {
          this.areas.splice(this.delete.key_1, 1);
        }

        if (this.delete.name == "concepts") {
          this.concepts.splice(this.delete.key_1, 1);
        }

        if (this.delete.name == "concept_user") {
          this.concept_user.splice(this.delete.key_1, 1);
        }

        if (this.delete.name == "note") {
          this.note.splice(this.delete.key_1, 1);
        }

        if (this.delete.name == "note_user") {
          this.note_user.splice(this.delete.key_1, 1);
        }

        if (this.delete.name === 'explain') {
          let valueExplan = this.extra_info[this.delete.key_1].value
          valueExplan.splice(this.delete.key_2, 1)
          this.extra_info[this.delete.key_1].value = valueExplan
        }
        if (this.delete.name === 'explain_item') {
          let valueExplanItem = this.extra_info[this.delete.key_1].value[this.delete.key_2].items
          valueExplanItem.splice(this.delete.key_3, 1)
          this.extra_info[this.delete.key_1].value[this.delete.key_2].items = valueExplanItem
        }

        this.$refs.modal_delete_entry.hide();
      },
      clearEntryToSave() {
        this.delete = {
          name: "",
          key_1: "",
          key_2: "",
        }
      },
      addExplain(type, index_explain, index_explain_item) {
        if (type === 'explain') {
          this.extra_info[index_explain].value.push(_.cloneDeep(this.extra_info_default[index_explain].value[0]))
        }
        if (type === 'explain_item') {
          let item = _.cloneDeep(this.extra_info_default[index_explain].value[0].items[0])
          this.extra_info[index_explain].value[index_explain_item].items.push(item)
        }
	    },
			checkSchedule(val, oldValue) {
				let check	= false
        if ((val.length !== oldValue.length)) {
          return true
        }
        val.forEach((schedule, index) => {
						if ((oldValue[index] === undefined)) {
              check = true
						} else {
              if ((Object.keys(oldValue[index]).length !== Object.keys(schedule).length)) {
                check = true
              } else {
                for (const item in schedule) {
                  if ((oldValue[index][item] !== schedule[item]) && (check === false)) {
                    check = true
                    if (item === 'detail') {
											check = this.checkDiff(schedule[item], oldValue[index][item])
										}

                  }
                }
              }
						}
				})
				return check
			},
			checkDiff(val, oldValue) {
        let check = false
				if ((val.length !== oldValue.length)) {
				  return true
				}
        val.forEach((schedule, index) => {
          for (const item in schedule) {
            if ((oldValue[index][item] !== schedule[item]) && (check === false)) {
              if(item === 'time_schedule') {
							  check = (oldValue[index][item].HH !== schedule[item].HH) || (oldValue[index][item].mm !== schedule[item].mm)
							} else {
                check = true
								if ([null, ''].includes(oldValue[index][item]) && [null, ''].includes(schedule[item])) {
								  check	= false
								}
              }
            }
					}
        })
        return check
			},
      formatPrice(value) {
        return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      },
			checkImageSize() {
        this.checkSize(this.entry.tour_order.tourname_image, 'tourname_image')
        this.checkSize(this.entry.tour_order.contact_image_preview, 'contact_image')
        this.checkSize(this.entry.tour_order.extra_image, 'extra_image')
        this.checkSizeUser(this.entry.tour_order.tour_image_user, 'tourname_image')
        this.checkSizeUser(this.entry.tour_order.contact_image_user, 'contact_image')
			},
			checkSize(source, type) {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          this.changeSize(img.width, img.height, type)
        }
        img.src = source;
			},
			checkSizeUser(source, type) {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          this.changeSizeUser(img.width, img.height, type)
        }
        img.src = source;
			},
      updateData(data) {
				this.entry[data.key] = data.value
      }
    }
  }
</script>
<style scoped lang="scss">

.tour-order-title {
  background-color: #f8d053;
  padding: 10px 20px;
}
.tour-order-completed {
  background-color: #cdcdcd;
}
.content-border {
  border: 1px solid grey;
}
.btn-secondary {
  color: #fff;
  background-color: #cdcdcd !important;
  border-color: #cdcdcd !important;
}

.modal-confirm {
	z-index: 9999 !important;
}
.tab-content {
	border: 1px solid grey;
	border-top: 0;
}
  .display-none {
    display: none !important;
  }
.vue__time-picker .controls > * {
	height: 40px !important;
	min-height: 40px;
}
</style>
