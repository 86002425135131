<template>
  <div class="col-md-12 col-xs-12 p-3 b-r-area b-b-area b-t-area">
    <AppText name="hearing_tour_purpose" input-style="normal" type="text"
             boundaryClass="col-md-11"
             :label="$t('booking.hearing_tour_purpose')"
             v-model="entry.hearing_tour_purpose"/>
    <AppInput name="hearing_budget" input-style="normal" type="string"
              :label="$t('booking.hearing_budget')"
              boundaryClass="col-md-11"
              v-model="entry.hearing_budget"/>
    <AppInput name="hearing_area" input-style="normal" type="string"
              :label="$t('booking.hearing_area')"
              boundaryClass="col-md-11"
              v-model="entry.hearing_area"/>
    <AppText name="hearing_plan_time" input-style="normal" type="string"
              :label="$t('booking.hearing_plan_time')"
              boundaryClass="col-md-11"
              v-model="entry.hearing_plan_time"/>
    <div class="col-md-6 row">
      <div class="col-md-12 p-0">
        <label class="control-label input-label p-0"
               style="margin-top:15px;">{{
          $t('booking.people_num_tour') }}</label>
      </div>
      <div class="col-md-1 p-0">
        <label class="control-label input-label p-0"
               style="margin-top:15px;">{{
          $t('booking.adult') }}</label>
      </div>
      <div class="col-md-5">
        <AppInput name="adult_count" input-style="normal" type="bigint"
                  rules="min_value:1|max_value:99"
                  v-model="entry.adult_count">
          <template slot="append">
																	<span style="margin: auto" class="app-text-bold">{{
																		  $t('common.people_count_icon')}}</span>
          </template>
        </AppInput>
      </div>
      <div class="col-md-1">
        <label class="control-label input-label p-0"
               style="margin-top:15px;">{{
          $t('booking.child') }}</label>
      </div>
      <div class="col-md-5 p-0">
        <AppInput name="child_count" input-style="normal" type="bigint"
                  rules="min_value:0|max_value:99"
                  v-model="entry.child_count">
          <template slot="append">
            <span style="margin: auto" class="app-text-bold">{{
                $t('common.people_count_icon')
              }}</span>
          </template>
        </AppInput>
      </div>
    </div>

    <AppText name="hearing_room_allocation" input-style="normal" type="text"
             boundaryClass="col-md-11"
             :label="$t('booking.hearing_room_allocation')"
             v-model="entry.hearing_room_allocation"/>
    <AppText name="hearing_meal_note" input-style="normal" type="text"
             boundaryClass="col-md-11"
             :label="$t('booking.food_diet')"
             v-model="entry.hearing_meal_note"/>
    <AppText name="hearing_tour_note" input-style="normal" type="text"
             boundaryClass="col-md-11"
             :label="$t('booking.hearing_tour_note')"
             v-model="entry.hearing_tour_note"/>
    <AppText name="hearing_other_note" input-style="normal" type="text"
             boundaryClass="col-md-11"
             :label="$t('booking.hearing_other_note')"
             v-model="entry.hearing_other_note"/>
    <AppText name="furusato_nozei_product_note" input-style="normal" type="text"
             boundaryClass="col-md-11" aria-valuemax="10000" v-if="entry.type == this.$consts.INPUT.TYPE_FURUSATO_NOZEI"
             rules="required"
             :label="$t('booking.furusato_nozei_product_note')"
             v-model="entry.furusato_nozei_product_note"/>
    <AppText name="furusato_nozei_product_note" input-style="normal" type="text"
             boundaryClass="col-md-11" aria-valuemax="10000" v-else
             :label="$t('booking.furusato_nozei_product_note')"
             v-model="entry.furusato_nozei_product_note"/>
  </div>
</template>
<script>
  export default {
    name: "HeadingSheet",
    props: ["entry", 'meta'],
  }
</script>
